import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import axios from 'axios'
import VueFuse from 'vue-fuse'
import firebase from 'firebase/compat/app'
import vuetify from './plugins/vuetify'
import isotope from 'isotope-layout'

// const firebaseConfig = {
//   apiKey: "AIzaSyDpqh4iqxN3zUQ3UE5n5ga8AzEGG8MsOHo",
//   authDomain: "taekwondo-school-check-in.firebaseapp.com",
//   databaseURL: "https://taekwondo-school-check-in-default-rtdb.firebaseio.com",
//   projectId: "taekwondo-school-check-in",
//   storageBucket: "taekwondo-school-check-in.appspot.com",
//   messagingSenderId: "70516549584",
//   appId: "1:70516549584:web:88751861046d73e66f3bd5"
// };

// firebase.initializeApp(firebaseConfig);

Vue.use(VueFuse)

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_REMOTE_API;

new Vue({
  isotope,
  router,
  store,
  render: h => h(App),
  vuetify,

  created () {
    const firebaseConfig = {
      apiKey: "AIzaSyDpqh4iqxN3zUQ3UE5n5ga8AzEGG8MsOHo",
      authDomain: "taekwondo-school-check-in.firebaseapp.com",
      databaseURL: "https://taekwondo-school-check-in-default-rtdb.firebaseio.com",
      projectId: "taekwondo-school-check-in",
      storageBucket: "taekwondo-school-check-in.appspot.com",
      messagingSenderId: "70516549584",
      appId: "1:70516549584:web:88751861046d73e66f3bd5"
    };
    firebase.initializeApp(firebaseConfig);
    // firebase.analytics();
  }
}).$mount('#app')
