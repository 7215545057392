<template>
  
      <v-form class="dataForm">
        <v-row>
            <v-text-field label="Rank Name" v-model="rankEdit.name">
            </v-text-field>
        </v-row>      
      </v-form>
  
</template>

<script>
import rankService from "../services/RankService"
export default {
  data() {
    return {
      alert: false,
        rankEdit: "",
        rankBeforeEdit: "",
    };
  },
  props: {
    rank: Object,
  },
  methods: {

    updateRank(){
      rankService
      .updateRank(this.rankEdit)
      .then((response) => {
        if (response.status === 200){
          this.$store.commit("UPDATE_RANK", this.rankEdit);
          this.$emit("rankUpdateSuccessful");
        } 
      })
      .catch((error) => {
        if (error.response) {
          this.errorMsg = `Error updating rank. ${error.response.status} - ${error.response.data.message}`;
        } else if (error.request) {
          this.errorMsg = "Could not connect to server.";
        } else {
          this.errorMsg = "Unexpected error";
        }
      })
    }
  },
  beforeMount(){
    this.rankEdit = Object.assign({}, this.rank);
    this.rankBeforeEdit = Object.assign({}, this.rank);
  }
};
</script>

<style>
.dataForm {
  padding: 1.5%;
}
</style>