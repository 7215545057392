<template>
  <v-container>
     <v-alert
      :value="this.addSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Email added successfully.
    </v-alert>
    <v-alert
      :value="this.editSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Email edited successfully.
    </v-alert>
    <v-data-table
      :items="this.emails"
      :headers="this.emailHeaders"
      class="membershipDataTable"
    >
      <template v-slot:[`item.actions`]="{ item }">
      <v-btn color="primary" dark  class="actionBtn" @click="showEditForm(item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
        <delete-prompt
          :item="item"
          :type="type"
          :typeLower="typeLower"
          v-on:deleteConfirm="deleteEmail(item.emailAddressId)"
        />
      </template>
    </v-data-table>
    <v-row>
      <v-spacer />
      <v-dialog @click:outside="destroyAddEmailForm()" v-model="addEmailDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="newEntryBtn"
            @click="addEmailDialog = true"
          >
            Add Email
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="destroyAddEmailForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add Email</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="addEmail()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <add-email-form
            v-if="showAddEmailForm"
            :membershipId="this.membershipId"
            @emailAddSuccessful="resetAddEmailForm()"
            ref="addEmailForm"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="editEmailDialog" @click:outside="destroyEditEmailForm()">
   
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="destroyEditEmailForm()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit E-Mail</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="updateEmail()"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <edit-email-form 
      :email="this.selectedEmail" 
      ref="emailEditForm"
      @emailEditSuccessful="resetEditEmailForm()"
      :key="destructionVar"
      />
      </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import DeletePrompt from "./DeletePrompt.vue";
import EditEmailForm from "./EditEmailForm.vue";
import emailAddressService from "../services/EmailAddressService";
import AddEmailForm from "./AddEmailForm.vue";

export default {
  components: { EditEmailForm, DeletePrompt, AddEmailForm },
  beforeMount() {
    this.setEmails();
  },
  methods: {
    updateEmail(){
      this.$refs.emailEditForm.updateEmail();
    },
    showEditForm(item) {
      this.destructionVar++;
      this.selectedEmail = item;
      this.editEmailDialog = true;
    },
    popUpEditSuccess(){
      this.editSuccess = true;
      setTimeout(() => (this.editSuccess = false), 5000);
    },
    popUpAddSuccess() {
      this.addSuccess = true;
      setTimeout(() => (this.addSuccess = false), 5000);
    },
    addEmail() {
      this.$refs.addEmailForm.addEmail();
    },
    setEmails() {
      this.emails = this.$store.state.emailAddressList.filter(
        (email) => email.membershipId == this.membershipId
      );
    },
    resetEditEmailForm(){
      this.destroyEditEmailFormWithSuccess();
      this.setEmails();
    },
    resetAddEmailForm() {
      this.destroyAddEmailFormWithSuccess();
      this.setEmails();
    },
    destroyEditEmailForm() {
      this.editEmailDialog = false;
      this.showEditEmailForm = false;
      setTimeout(() => (this.showEditEmailForm = true), 250);
    },
    destroyEditEmailFormWithSuccess() {
      this.editEmailDialog = false;
      this.popUpEditSuccess();
      this.showEditEmailForm = false;
      setTimeout(() => (this.showEditEmailForm = true), 250);
    },
    destroyAddEmailFormWithSuccess() {
      this.addEmailDialog = false;
      this.popUpAddSuccess();
      this.showAddEmailForm = false;
      setTimeout(() => (this.showAddEmailForm = true), 250);
    },
    destroyAddEmailForm() {
      this.addEmailDialog = false;
      this.showAddEmailForm = false;
      setTimeout(() => (this.showAddEmailForm = true), 250);
    },
    deleteEmail(id) {
      const idNum = Number(id);
      emailAddressService
        .deleteEmail(idNum)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("DELETE_EMAIL", idNum);
            this.setEmails();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error deleting email. ${error.response.status} - ${error.response.statusText}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
        });
    },
  },
  props: {
    membershipId: [String, Number],
  },
  data() {
    return {
      selectedEmail: Object,
      editSuccess: false,
      showEditEmailForm: true,
      editEmailDialog: false,
      destructionVar: 0,
      addSuccess: false,
      showAddEmailForm: true,
      addEmailDialog: false,
      type: "Email",
      typeLower: "email",
      emails: [],
      emailHeaders: [
        {
          text: "Owner",
          value: "emailAddressOwnerName",
          width: "20%",
        },
        {
          text: "Type",
          value: "emailAddressType",
          width: "15%",
        },
        {
          text: "E-mail",
          value: "emailAddress",
          width: "auto",
        },
        { text: "Actions", value: "actions", sortable: false, width: "20%" },
      ],
    };
  },
};
</script>

<style>
.membershipDataTable {
  margin-top: 3%;
  margin-left: 4%;
}
</style>