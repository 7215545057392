import axios from 'axios';
import store from '../store/index';

const firebaseToken = store.state.token;

const http = axios.create({
    // baseURL: "http://192.168.0.113:8080"
        // baseURL: "http://localhost:8080",
    baseURL: "https://taekwondo-school-check-in.uc.r.appspot.com/"
  });

export default {

    getMemberships() {
        return http.get('/memberships', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
    updateMembership(editedMembership){
        return http.put('/memberships', editedMembership, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
    addMembership(membership){
        return http.post('/memberships', membership, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    }
}