<template>
  <v-container>
     <v-alert
      :value="this.addSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Program added successfully.
    </v-alert>
    <v-data-table :items="allPrograms" :headers="headers" item-key="programId">
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" dark @click="viewProgram(item.programId)">
          <v-icon> mdi-eye </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row>
    <v-spacer/>
    <v-dialog 
      @click:outside="destroyAddProgramForm()" 
      v-model="addProgramDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="addProgramDialog = true"
          >
            Add Program
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="destroyAddProgramForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add Program</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="addProgram()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

        <add-program-form 
        v-if="showAddProgramForm"
        ref="addProgramForm"
        @programAddedSuccessful="resetAddProgramForm()"
        />
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
// import firebase from 'firebase'
import AddProgramForm from "./AddProgramForm.vue"

export default {
  data(){
    return {
showAddProgramForm: true,
addProgramDialog: false,
addSuccess: false,
    }
  },
  components: {AddProgramForm},
  name: "programsTable",
  props: ["program"],
  methods: {
    popUpAddSuccess() {
      this.addSuccess = true;
      setTimeout(() => (this.addSuccess = false), 5000);
    },
      resetAddProgramForm(){
        this.popUpAddSuccess();
      this.destroyAddProgramForm();
    },  
     destroyAddProgramForm() {
      this.addProgramDialog = false;
      this.showAddProgramForm = false;
      setTimeout(() => (this.showAddProgramForm = true), 250);
    },
    addProgram() {
      this.$refs.addProgramForm.addProgram();
    },
    viewProgram(id) {
      //think about refactoring to use already received data, reduce calls to db
      let programId = Number(id);
      this.$router.push({ name: "View Program", params: { programId } });
    },
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "name", width: "92%" },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    allPrograms: function () {
      return this.$store.state.programList;
    },
  },
};
</script>

<style >
.newProgramBtn{
  margin-right: 2%;
}
</style>