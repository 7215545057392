<template>
  
  <v-container fluid class ="homePage">
 
  </v-container>
</template>

<script>
// import SuccessStories from "@/components/SuccessStories";
// import StoryService from '@/services/StoryService';
import programService from "../services/ProgramService";
import membershipService from "../services/MembershipService";
import studentService from "../services/StudentService";
import enrollmentService from "../services/EnrollmentService";
import rankService from "../services/RankService";
import addressService from "../services/AddressService";
import emailAddressService from "../services/EmailAddressService";
import phoneNumberService from "../services/PhoneNumberService";
import membershipTypeService from "../services/MembershipTypeService";

export default {
    name: "homeView",
    components: {
      
    },
    methods: {
      retrievePrograms() {
      programService.getPrograms().then((response) => {
        this.$store.commit("SET_PROGRAM_LIST", response.data);
      });
    },
    retrieveMemberships() {
      membershipService.getMemberships().then((response) => {
        this.$store.commit("SET_MEMBERSHIP_LIST", response.data);
      });
    },
    retrieveStudents() {
      studentService.getStudents().then((response) => {
        this.$store.commit("SET_STUDENT_LIST", response.data);
      });
    },
    retrieveEnrollments() {
      enrollmentService.getEnrollments().then((response) => {
        this.$store.commit("SET_ENROLLMENT_LIST", response.data);
      });
    },
    retrieveRanks() {
      rankService.getRanks().then((response) => {
        this.$store.commit("SET_RANK_LIST", response.data);
      });
    },
    retrieveAddresses() {
      addressService.getAddresses().then((response) => {
        this.$store.commit("SET_ADDRESS_LIST", response.data);
      });
    },
    retrieveEmails() {
      emailAddressService.getEmails().then((response) => {
        this.$store.commit("SET_EMAIL_ADDRESS_LIST", response.data);
      });
    },
    retrievePhoneNumbers() {
      phoneNumberService.getPhoneNumbers().then((response) => {
        this.$store.commit("SET_PHONE_NUMBER_LIST", response.data);
      });
    },
    retrieveMembershipTypes() {
      membershipTypeService.getMembershipTypes().then((response) => {
        this.$store.commit("SET_MEMBERSHIP_TYPE_LIST", response.data);
      });
    },

    },
    created() {
      document.title = 'Home';
      this.retrievePrograms();
    this.retrieveMemberships();
    this.retrieveStudents();
    this.retrieveEnrollments();
    this.retrieveRanks();
    this.retrieveAddresses();
    this.retrieveEmails();
    this.retrievePhoneNumbers();
    this.retrieveMembershipTypes();
      
    },
    data() {
      return {};
    },

};
</script>


<style scoped>
.homePage {
  position: absolute;
  z-index: 1;
  margin-top: 12%;
  height: 100%;
  width: 100%;
  margin-left: 8%;
}
</style>
