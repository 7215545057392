import axios from 'axios';
import store from '../store/index';

const firebaseToken = store.state.token;

const http = axios.create({
    // baseURL: "http://192.168.0.113:8080"
    //    baseURL: "http://localhost:8080",
    baseURL: "https://taekwondo-school-check-in.uc.r.appspot.com/"
  });

export default {

    addRank(rank){
        return http.post('/ranks', rank, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        })
    },

    getRanks(){
        return http.get('/ranks', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
   
    updateRank(editedRank){
        return http.put('/ranks', editedRank, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
   
}