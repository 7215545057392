<template>
  <div class="nav"> 
      <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        School Management
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>

  <v-divider/>

<v-list dense nav>

  <v-list-item>

      <router-link class="button" v-bind:to="{name: 'Home'}">
        <h2>Home</h2>
      </router-link>

  </v-list-item>

  <v-list-item>

      <router-link class="button" v-bind:to="{name: 'Programs'}">
        <h2>Programs</h2>
      </router-link>

  </v-list-item>

  <v-list-item>

        <router-link class="button" v-bind:to="{name: 'Memberships'}">
        <h2>Memberships</h2>
      </router-link>
  
  </v-list-item>

  <v-list-item>
   
     <router-link class="button" v-bind:to="{name: 'Students'}">
        <h2>Students</h2>
      </router-link>
 
  </v-list-item>

  <v-list-item>
 
    
        <h2>Instructors</h2>
  
   
  </v-list-item>


  <v-list-item>

    <router-link class="button" v-bind:to="{name: 'Check-In'}">
        <h2>Check-In</h2>
    </router-link>
   
  </v-list-item>

  <v-list-item>
   
    
        <h2>Users</h2>
  
  </v-list-item>

  <v-list-item>
   
    
        <h2>Settings</h2>
  
    
  </v-list-item>
</v-list>

 

</div>
</template>

<script>

export default {
  name: "NavMain",
  data() {
    return {
    }
  },
  computed: {
    },
  methods: { 
  }

};
</script>

<style scoped>

/* .header-main {
  display: grid;
  position: absolute;
  top: 0;
  font-family: 'Subscriber';
  font-size: 350%;
  grid-template-columns: 2% 12% 15% 15% 15% 15% 10% 16%;
  grid-template-rows: 33% 34% 33%;
  grid-template-areas:
    ". logo . . . . . ."
    ". logo feat cats success tools search logout"
    ". logo . . . . . .";
  justify-content: space-evenly;
  
  z-index: 0;
} */

.nav {

}
.button {
  margin-top: -3%;
  /* margin-left: 7%; */
  height: 110%;
  justify-self: left;
  align-self: center;
  /* border-radius: 50px; */
  /* justify-self: center;
  box-shadow: -1px 2px 8px 1px rgba(0, 0, 0, 0.5); */
  text-decoration: none;
  padding-left: 2%;
  padding-right: 5%;
}
</style>