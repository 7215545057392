<template>
  
      <v-form class="editMembershipTypeForm">
        <v-alert
        :value="alert"
        border="top"
        type="error"
        transition="scale-transition">
        {{this.errorMsg}}
        </v-alert>
        
        <v-row>
          <v-col>
            <v-text-field v-model="membershipTypeEdit.membershipTypeName" label="Membership Type">
            </v-text-field>
          </v-col>

          <v-col>
           <v-text-field v-model="membershipTypeEdit.membershipTypeLength" label="Membership Type Length (In Months)"></v-text-field>
          </v-col>
          <v-col>
           <v-text-field v-model="membershipTypeEdit.membershipTypeCostPerMonth" label="Membership Type Cost Per Month (In $)" prefix="$">$</v-text-field>
          </v-col>
        </v-row>
      </v-form>
  
</template>

<script>
import membershipTypeService from "../services/MembershipTypeService";

export default {
  props: {
    membershipType: Object,

  },
  data() {
    return {
      membershipTypeEdit: Object,
      membershipTypeBeforeEdit: Object,
      formDialog: "",
      alert: false,
      errorMsg: "",
    };
  },
   created() {
    this.membershipTypeEdit = Object.assign({}, this.membershipType);
    this.membershipTypeBeforeEdit = Object.assign({}, this.membershipType);

  },
  methods: {
    resetForm() {
      this.formDialog = false;
      this.alert = false;
      this.errorMsg = null;
      setTimeout(
        () =>
          (this.membershipTypeEdit = Object.assign({}, this.membershipTypeBeforeEdit, this.membershipTypeEdit)),
        250
      );
    },
    updateMembershipType() {
      membershipTypeService
        .updateMembershipType(this.membershipTypeEdit)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("UPDATE_MEMBERSHIP_TYPE", this.membershipTypeEdit);
            this.$emit("membershipTypeEditSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error updating Membership Type. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },

},
 
}
</script>

<style>

.editMembershipTypeForm {
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 1%;
}

</style>