<template>
  <v-dialog v-model="formDialog" @click:outside="resetEditProgramForm">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        <v-icon> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="resetEditProgramForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit Program</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="updateProgram(programEdit)"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-alert
        :value="alert"
        border="top"
        type="error"
        transition="scale-transition"
      >
        {{ this.errorMsg }}
      </v-alert>
      <v-form class="dataForm">
        <v-row>
          <v-text-field
            label="Program Name"
            v-model="programEdit.name"
          ></v-text-field>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import programService from "../services/ProgramService";
export default {
    beforeMount(){
    this.programEdit = Object.assign({}, this.program);
    this.programBeforeEdit = Object.assign({}, this.program);
  },
    props: {
        program: Object,
    },
  data() {
    return {
      alert: false,
      errorMsg: "",
      formDialog: false,
      programEdit: Object,
      programBeforeEdit: Object,
    };
  },
  methods: {
    updateProgram(program) {
      programService
        .updateProgram(program)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("UPDATE_PROGRAM", program);
            this.formDialog = false;
            this.$emit("programUpdateSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error updating program. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },
    resetEditProgramForm() {
      this.formDialog = false;
      this.programEdit = Object.assign({}, this.programBeforeEdit);
    },
  },
};
</script>

<style>
</style>