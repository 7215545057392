import axios from 'axios';
import store from '../store/index';

const firebaseToken = store.state.token;

const http = axios.create({
    // baseURL: "http://192.168.0.113:8080"
    //    baseURL: "http://localhost:8080",
    baseURL: "https://taekwondo-school-check-in.uc.r.appspot.com/"
  });

export default {

    getPrograms() {
        return http.get('/programs', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
   
    addProgram(newProgram){
        return http.post('/programs' ,newProgram, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },

    updateProgram(editedProgram){
        return http.put('/programs', editedProgram, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    }
}