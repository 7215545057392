<template>
  
      
      <v-form class="dataForm">
        <v-alert
          :value="alert"
          border="top"
          type="error"
          transition="scale-transition"
          :dismissible=true
        >
          {{ this.errorMsg }}
        </v-alert>
        <v-row>
            <v-text-field
              label="Program Name"
              v-model="newProgram.name"
            ></v-text-field>

        </v-row>
      </v-form>

</template>

<script>
import programService from "../services/ProgramService"
export default {
data() {
    return {
      alert: false,
        errorMsg: "",
      newProgram: {
        name: "",
      },
      cleanProgram: {
          name: "",
      },
    };
  },
  methods: {
      addProgram(){
          programService
          .addProgram(this.newProgram)
          .then((response) => {
              if (response.status === 200){
                  this.newProgram.programId = response.data;
                  this.$store.commit("ADD_PROGRAM", this.newProgram);
                  this.$emit("programAddedSuccessful");
              }
          })
          .catch((error) => {
              if (error.response) {
                  this.errorMsg = `Error adding program. ${error.response.status} - ${error.response.data.message}`;
              } else if (error.request) {
                  this.errorMsg = "Could not connect to server";
              } else {
                  this.errorMsg = "Unexpected error";
              }
               this.alert = true;
          });
      },
    resetAddProgramForm() {
      this.formDialog = false;
      this.newProgram = Object.assign({}, this.cleanProgram);
    },
  },
  beforeMount() {
    this.newProgram = Object.assign({}, this.cleanProgram);
  },
};

</script>

<style>
.newEntryBtn {
  margin: 0.5%;
}

.dataForm {
  padding: 1.5%;
}
</style>