<template>
  <edit-enrollment-form/>
</template>

<script>
import EditEnrollmentForm from '../components/EditEnrollmentForm.vue'
export default {
  components: { EditEnrollmentForm },

}
</script>

<style>

</style>