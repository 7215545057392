<template>
  <v-dialog
    v-model="formDialog"
    @keydown.esc="formDialog = false"
    class="text-center"
    width="450"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        class="actionBtn"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="formDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Delete {{ this.type }} </v-toolbar-title>
      </v-toolbar>
    
          <v-card-text class="confirmTxt px-7 pt-7 pb-4 mx-auto text-center d-inline-block">
          
            Are you sure you want to delete this {{ this.typeLower }}?
          
          </v-card-text>
   
        <v-card-actions>
          <v-spacer/>
          <v-btn class="ma-1" color="grey" @click="formDialog = false">No</v-btn>
          <v-btn class="ma-1" color="error" @click="confirmDelete()"> Yes</v-btn>
        </v-card-actions>
  
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  directives: {
  },
  data() {
    return {
      formDialog: false,
    };
  },
  props: {
    item: Object,
    type: String,
    typeLower: String,
  },
  computed: {

  },
  methods: {
    confirmDelete(){
      this.$emit("deleteConfirm");
      this.formDialog =false;

      }
    },
};
</script>

<style>
.actionBtn {
  margin: 1.5%;
}

.confirmTxt{
  font-size: 100%;
  font-weight: 500;
}
</style>