<template>
  <v-form  class="dataForm" ref="enrollmentForm">
    <v-alert
          :value="alert"
          border="top"
          type="error"
          transition="scale-transition"
          :dismissible=true
        >
          {{ this.errorMsg }}
        </v-alert>
    <v-row v-if="multipleStudents">
      <v-col>
        <v-combobox
          label="Student"
          :items="filteredStudentList"
          @change="setStudent($event)"
          item-text="firstName"
          item-value="studentId"
        ></v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-combobox
          label="Program"
          :items="this.$store.state.programList"
          @change="setProgramAndRanks($event)"
          item-text="name"
          item-value="programId"
        >
        </v-combobox>
      </v-col>

      <v-col>
        <v-combobox
        :disabled="!programSet"
          label="Rank"
          :items="this.programRanks"
          item-text="name"
          item-value="rankId"
          @change="setEnrollmentRank($event)"
        >
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-menu
          ref="enrollmentStartDateSelector"
          v-model="enrollmentStartDateSelector"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-on:focus="birthdaySelector = true"
              :value="formattedEnrollmentStartDate"
              label="Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newEnrollment.startDate"
            @change="setEnrollmentStartDate"
            @input="enrollmentStartDateSelector = false"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row >
      <v-spacer />
      <v-btn
        
        class="saveEnrollmentBtn"
        @click="addEnrollment()"
      >
        Save Enrollment</v-btn
      >

   
    </v-row>
  </v-form>
</template>

<script>
import enrollmentService from "../services/EnrollmentService";
import moment from "moment";
export default {
  props: {
    filteredStudentList: Array,
    studentId: [String, Number]
  },
  data() {
    return {
      alert: false,
      programSet: false,
      enrollmentFormDisabled: false,
      dateFormat: "MM/DD/YYYY",
      formattedEnrollmentStartDate: "",
      enrollmentStartDateSelector: false,
      errorMsg: "",
      programRanks: [],
      newEnrollment: {
        enrollmentId: "",
        studentId: "",
        programId: "",
        rankId: "",
        active: true,
        startDate: "",
        endDate: "0001-01-01",
      },
      enrollmentClean: {
        enrollmentId: "",
        studentId: "",
        programId: "",
        rankId: "",
        active: true,
        startDate: "",
        endDate: "0001-01-01",
      },
    };
  },
  computed: {
    multipleStudents() {
      return (
        this.$props.filteredStudentList.length > 1
      );
    },
  },
  methods: {
    resetForm() {
      this.$refs.enrollmentForm.reset();
      this.alert = false;
      this.errorMsg = null;
      setTimeout(
        () => (this.newEnrollment = Object.assign({}, this.enrollmentClean)),
        250
      );
    },
    toggleEnrollment() {
      this.enrollmentFormDisabled = false;
      this.$emit("resetEnrollmentForm");
    },
    setProgramAndRanks(event) {
      this.newEnrollment.programId = event.programId;
      this.programSet = true;
      this.programRanks = this.$store.state.rankList.filter(
        (r) => r.programId == this.newEnrollment.programId
      );
    },
    setStudent(event) {
      this.newEnrollment.studentId = event.studentId;
    },
    addEnrollment() {
       if (this.$props.filteredStudentList.length == 1){
          if (typeof(this.filteredStudentList[0]) === 'string' || typeof(this.filteredStudentList[0] === 'number')){
            this.newEnrollment.studentId = this.filteredStudentList[0];
          } else {
this.newEnrollment.studentId = this.$props.filteredStudentList[0].studentId;
          }
              
            }
      enrollmentService
        .addEnrollment(this.newEnrollment)
        .then((response) => {
          
          if (response.status === 200) {
           
            this.newEnrollment.enrollmentId = response.data;
            this.newEnrollment.endDate = "0001-01-01";
            this.newEnrollment.active = true;
            this.enrollmentFormDisabled = true;
            this.$store.commit("ADD_ENROLLMENT", this.newEnrollment);
            this.resetForm();
            this.$emit("enrollmentAddSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error adding enrollment. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },
    addStudentEnrollment() {
      enrollmentService
        .addEnrollment(this.newEnrollment)
        .then((response) => {
          if (response.status === 200) {
            this.newEnrollment.enrollmentId = response.data;
            this.newEnrollment.endDate = "0001-01-01";
            this.newEnrollment.active = true;
            this.enrollmentFormDisabled = true;
            this.$store.commit("ADD_ENROLLMENT", this.newEnrollment);
            this.resetForm();
            this.$emit("enrollmentAddSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error adding enrollment. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },
    setEnrollmentStartDate() {
      this.formattedEnrollmentStartDate = moment(
        this.newEnrollment.startDate
      ).format(this.dateFormat);
    },
    setEnrollmentRank(event) {
      this.newEnrollment.rankId = event.rankId;
    },
  },
};
</script>

<style>
.dataForm {
  padding: 1.5%;
}
</style>