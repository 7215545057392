<template>
  <login/>
</template>

<script>
import Login from '../components/Login.vue'
export default {
    name: "loginScreen",
    components: {Login},

}
</script>

<style>

</style>