import axios from 'axios';
import store from '../store/index';

const firebaseToken = store.state.token;

const http = axios.create({
    // baseURL: "http://192.168.0.113:8080"
        // baseURL: "http://localhost:8080",
    baseURL: "https://taekwondo-school-check-in.uc.r.appspot.com/"
  });

export default {

    getEmails(){
        return http.get('/emails', {
            headers: {
                'Content-Type':  'application/json',
                'Authorization': firebaseToken
            }
        });
    },
    
    updateEmail(editedEmail){
        return http.put('/emails', editedEmail, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },

    deleteEmail(id){
        return http.delete(`/emails/${id}`, {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': firebaseToken
            }
        });
    },

    addEmail(newEmail){
        return http.post('/emails', newEmail, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    }
   
}