<template>
  <v-container>
    <v-container id="firebaseui-auth-container"> </v-container>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/analytics'
// import 'firebase-functions';
// import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  methods: {
    googleSignin: function() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth()
              .signInWithPopup(provider)
              .then(() => {
                firebase.auth().currentUser.getIdToken().then((result) => this.$store.commit("SET_AUTH_TOKEN", result));
                this.$router.push('/home');
              })
              .catch(() => {
              })
    }
    // setAuthToken(token) {
    //   this.$store.commit("SET_AUTH_TOKEN", token)
    // }
  },
  mounted(){
    this.googleSignin();
  }
  // mounted() {
  //   // firebase.functions().useEmulator("127.0.0.1", 5001);
  //   let ui = firebaseui.auth.AuthUI.getInstance();
  //   if (!ui) {
  //     ui = new firebaseui.auth.AuthUI(firebase.auth());
  //   }

  //   const self = this;
  //   const uiConfig = {
  //     callbacks: {
  //       signInSuccessWithAuthResult: function () {
  //         firebase.auth().currentUser.getIdTokenResult()
  //           .then(function (idTokenResult) {
  //             if (idTokenResult.claims.admin) {
  //               self.$store.commit("SET_AUTH_TOKEN", idTokenResult.token);
  //             }

  //           });

  //         return true;
  //       }
  //     },
  //     signInSuccessURL: '/home',
  //     signInFlow: 'popup',
  //     signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  //   };
  //   ui.start("#firebaseui-auth-container", uiConfig);
    

  // },
};
</script>

<style></style>