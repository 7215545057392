<template>
  <student-data/>
</template>

<script>
import StudentData from "../components/StudentData.vue"

export default {
  components: { StudentData },

}
</script>

<style>

</style>