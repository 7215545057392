<template>
  <check-in/>
</template>

<script>
import CheckIn from '../components/CheckIn.vue'
export default {
  name: "Check-In",
  components: { CheckIn },

}
</script>

<style>

</style>