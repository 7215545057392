<template >
  <v-container :key="this.rankUpdate">
    <v-alert
      :value="this.updateSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Rank updated successfully.
    </v-alert>
    <v-alert
      :value="this.addSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Rank added successfully.
    </v-alert>
    <v-data-table :items="ranks" :headers="headers" item-key="rankId">
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" dark @click="showEditForm(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row>
      <v-spacer />
      <v-dialog @click:outside="destroyAddRankForm()" v-model="addRankDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="newEntryBtn"
            @click="addRankDialog = true"
          >
            Add Rank
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="destroyAddRankForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add Rank</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="addRank()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <add-rank-form
            v-if="showAddRankForm"
            :programId="this.programId"
            @rankAddedSuccessful="resetAddRank()"
            ref="addRankForm"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="editRankDialog" @click:outside="destroyEditRankForm()">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="destroyEditRankForm()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Edit Rank</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="editRank()"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <edit-rank-form
        :rank="this.selectedRank"
          ref="editRankForm"
          @rankUpdateSuccessful="destroyEditRankFormWithSuccess()"
          :key="destructionVar"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AddRankForm from "./AddRankForm.vue";
import EditRankForm from "./EditRankForm.vue";

export default {
  components: { EditRankForm, AddRankForm },
  props: {
    programId: [String, Number],
  },
  data() {
    return {
      selectedRank: Object,
      editRankDialog: false,
      editRankForm: true,
      destructionVar: 0,
      addRankDialog: false,
      showAddRankForm: true,
      rankUpdate: 0,
      ranks: [],
      updateSuccess: false,
      addSuccess: false,
      headers: [
        { text: "Rank", value: "name", width: "99%" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    showEditForm(item) {
      this.destructionVar++;
      this.selectedRank = item;
      this.editRankDialog = true;
    },
     destroyAddRankForm() {
      this.addRankDialog = false;
      this.showAddRankForm = false;
      setTimeout(() => (this.showAddRankForm = true), 250);
    },
    destroyEditRankForm() {
      this.editRankDialog = false;
      this.showEditRankForm = false;
      setTimeout(() => (this.showEditRankForm = true), 250);
    },
    destroyEditRankFormWithSuccess() {
      this.editRankDialog = false;
      this.popUpUpdateSuccess();
      this.showEditRankForm = false;
      setTimeout(() => (this.showEditRankForm = true), 250);
    },
    destroyAddRankFormWithSuccess() {
      this.addRankDialog = false;
      this.popUpAddSuccess();
      this.showAddRankForm = false;
      setTimeout(() => (this.showAddRankForm = true), 250);
    },
    resetAddRank() {
      this.destroyAddRankForm();
      this.setRanks();
    },
    editRank(){
      this.$refs.editRankForm.updateRank();
    },
    addRank() {
      this.$refs.addRankForm.addRank();
    },
    popUpUpdateSuccess() {
      this.updateSuccess = true;
      setTimeout(() => (this.updateSuccess = false), 5000);
      this.setRanks();
      this.rankUpdate += 1;
    },
    popUpAddSuccess() {
      this.addSuccess = true;
      setTimeout(() => (this.addSuccess = false), 5000);
      this.setRanks();
      this.rankUpdate += 1;
    },
    setRanks() {
      this.ranks = this.$store.state.rankList.filter(
        (rank) => rank.programId == this.programId
      );
    },
  },
  beforeMount() {
    this.setRanks();
  },
};
</script>

<style>
</style>