<template>
  <v-container>
     <v-alert
      :value="this.editSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Membership edited successfully.
    </v-alert>
    <membership-data
      :membership="this.membership"
      @membershipUpdateSuccessful="membershipChanged()"
      :key="membershipKey"
    />
    <h2 class="membershipDataHeading">Students</h2>
    <students-table :students="this.students" class="dataTable"  :membershipId="this.membershipId"/>
    <v-row>
      <v-spacer />
    </v-row>
    <h2 class="membershipDataHeading">Addresses</h2>
    <address-table
      :membershipId="this.membership.membershipId"
    />

    <h2 class="membershipDataHeading">E-Mails</h2>
    <email-table :membershipId="this.membership.membershipId" />

    <h2 class="membershipDataHeading">Phone Numbers</h2>
    <phone-number-table :membershipId="this.membership.membershipId" />
    
  </v-container>
</template>

<script>
import AddressTable from "../components/AddressTable.vue";
import EmailTable from "../components/EmailTable.vue";
import PhoneNumberTable from "../components/PhoneNumberTable.vue";
import StudentsTable from "../components/StudentsTable.vue";
import moment from "moment";
import MembershipData from "../components/MembershipData.vue";
// import firebase from "firebase/compat/app";
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

export default {
   name: "membershipView",
  components: {
    AddressTable,
    EmailTable,
    PhoneNumberTable,
    StudentsTable,
    MembershipData,
  },
  data() {
    return {
      editSuccess: false,
      dateFormat: "MM/DD/YYYY",
      membershipId: this.$route.params.membershipId,
      membership: "",
      membershipKey: 0,
    };
  },
  methods: {
    membershipChanged() {
      this.editSuccess = true;
      
      this.membershipKey += 1;
      setTimeout(() => (this.editSuccess = false), 5000);
    },
    setMembership() {
      this.membership = this.$store.state.membershipList.find(
        (membership) => membership.membershipId == this.membershipId
      );
    },
    formatDate(x) {
      return moment(x).format(this.dateFormat);
    },
  },
  computed: {
    students: function () {
      return this.$store.state.studentList.filter(
        (student) => student.membershipId == this.membershipId
      );
    },
    membershipTypeName: function () {
      let membershipTypeName = "";
      if (this.membership.membershipType) {
        let membershipTypeFound = this.$store.state.membershipTypeList.find(
          (membershipType) =>
            membershipType.membershipTypeId == this.membership.membershipType
        );
        membershipTypeName = membershipTypeFound.membershipTypeName;
      }

      return membershipTypeName;
    },
  },
  created() {
    this.setMembership();
  }

};
</script>

<style>
.membershipDataHeading {
  margin: 1%;
}
</style>