<template>
  
      <v-form class="editMembershipForm">
        <v-alert
        :value="alert"
        border="top"
        type="error"
        transition="scale-transition">
        {{this.errorMsg}}
        </v-alert>

        <v-row>
          <v-col>
            <v-text-field
              label="Membership Name"
              v-model="membershipEdit.membershipName"
            />
          </v-col>

          <v-col>
            <v-text-field
              label="Membership Primary Contact Name"
              v-model="membershipEdit.membershipContactName"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              ref="startDateSelector"
              v-model="startDateSelector"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedStartDate"
                  label="Start Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="membershipEdit.startDate"
                @change="setStartDate()"
                @input="startDateSelector = false"
                :active-picker.sync="activePicker"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              ref="endDateSelector"
              v-model="endDateSelector"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  clearable
                  @click:clear="clearEndDate()"
                  :value="formattedEndDate"
                  label="End Date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="membershipEdit.endDate"
                @change="setEndDate()"
                @input="endDateSelector = false"
                :active-picker.sync="activePicker"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-combobox
              :value="getMembershipTypeName(this.membershipEdit.membershipType)"
              :items="this.$store.state.membershipTypeList"
              label="Membership Type"
              item-text="membershipTypeName"
              item-value="membershipTypeId"
              @change="setNewMembershipType($event)"
            >
            </v-combobox>
          </v-col>
        </v-row>
      </v-form>
    
</template>

<script>
import moment from "moment";
import membershipService from "../services/MembershipService";

export default {
  created() {
    this.membershipEdit = Object.assign({}, this.membership);
    this.membershipBeforeEdit = Object.assign({}, this.membership);
    const current = new Date();
    const today = moment(current).format(this.dateFormat2);
    this.membershipEdit.endDate = today;
    this.formattedStartDate = moment(this.membership.startDate).format(
      this.dateFormat
    );
    this.endDateCheck = new Date(this.membership.endDate);
    if (this.membershipBeforeEdit.endDate.toString() != "0001-01-01") {
      this.formattedEndDate = moment(this.membershipBeforeEdit.endDate).format(
        this.dateFormat
      );
    } else {
      this.formattedEndDate = "";
    }
  },
  props: {
    membership: Object,
  },
  data() {
    return {
      editSuccess: false,
      membershipEdit: Object,
      membershipBeforeEdit: Object,
      alert: false,
      errorMsg: "",
      endDateCheck: Object,
      dateCheck: new Date("0001-01-01"),
      formattedStartDate: "",
      formattedEndDate: "",
      dateFormat: "MM/DD/YYYY",
      dateFormat2: "YYYY-MM-DD",
      startDateSelector: false,
      endDateSelector: false,
      activePicker: "",
      endDateSet: false,
    };
  },
  methods: {
    updateMembership() {
      if (!this.endDateSet) {
        this.membershipEdit.endDate = "0001-01-01";
      }
      membershipService
        .updateMembership(this.membershipEdit)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("UPDATE_MEMBERSHIP", this.membershipEdit);
            this.$emit("membershipUpdateSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error updating membership. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
        });
    },
    getMembershipTypeName(membershipTypeId) {
      let membershipTypeName = "";
      if (this.membership.membershipType){
      let membershipType = this.$store.state.membershipTypeList.find(
        (membershipTypeSearch) =>
          membershipTypeSearch.membershipTypeId == membershipTypeId
      );
      membershipTypeName = membershipType.membershipTypeName;
      }
      return membershipTypeName;
    },
    setNewMembershipType(event) {
      this.membershipEdit.membershipType = event.membershipTypeId;
    },
    setStartDate() {
      this.formattedStartDate = moment(this.membershipEdit.startDate).format(
        this.dateFormat
      );
    },
    setEndDate() {
      this.endDateSet = true;
      this.membershipEdit.active = false;
      this.formattedEndDate = moment(this.membershipEdit.endDate).format(
        this.dateFormat
      );
    },
    resetEndDate() {
      this.endDateSet = false;
      this.membershipBeforeEdit.active = true;
      this.formattedEndDate = null;
      const current = new Date();
      const today = moment(current).format(this.dateFormat2);
      this.membershipBeforeEdit.endDate = today;
    
    },
    clearEndDate(){
      this.endDateSet = false;
      this.membershipEdit.active = true;
      this.formattedEndDate = null;
      const current = new Date();
      const today = moment(current).format(this.dateFormat2);
      this.membershipEdit.endDate = today;
    },
    formatDate(date) {
      return moment(date).format(this.dateFormat);
    },
  },
};
</script>

<style>
.editMembershipForm {
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 1%;
}

.editBtn {
  margin-left: 75%;
}
</style>