<template>

      <v-form class="dataForm">
        <v-row>
            <v-text-field label="Rank Name" v-model="newRank.name">
            </v-text-field>
        </v-row>      
      </v-form>
</template>

<script>
import rankService from "../services/RankService"
export default {
data() {
    return {
        errorMsg: "",
      newRank: {
        name: "",
        programId: this.programId,
      },
      cleanRank: {
          name: "",
          programId: this.programId,
      },
    };
  },
   props: {
    programId: [String, Number],
  },

  methods: {
      addRank(){
          rankService
          .addRank(this.newRank)
          .then((response) => {
              if (response.status === 200){
                  this.newRank.rankId = response.data;
                  this.$store.commit("ADD_RANK", this.newRank);
                  this.$emit("rankAddedSuccessful");
              }
          })
          .catch((error) => {
              if (error.response) {
                  this.errorMsg = `Error adding rank. ${error.response.status} - ${error.response.data.message}`;
              } else if (error.request) {
                  this.errorMsg = "Could not connect to server";
              } else {
                  this.errorMsg = "Unexpected error";
              }
            //   this.alert = true;
          });
      },
    resetForm() {
      this.formDialog = false;
      this.newRank = Object.assign({}, this.cleanRank);
    },
  },
  beforeMount() {
    this.newRank = Object.assign({}, this.cleanRank);
  },
};

</script>

<style>

</style>