<template  >
    <fullscreen v-model="fullscreen"  class="checkIn">
    <v-container >

        <v-btn  @click="toggle" v-if="!fullscreen">Fullscreen</v-btn>
        <v-spacer />
        <v-btn   class="letters rounded-circle " width="77px" height="77px" v-for="letter in letters" v-bind:key="letter" @click="filterStudents(letter)"> <h3 class="buttons">{{letter}} </h3></v-btn>
        <v-btn @click="resetStudents" ><h3>Reset</h3></v-btn>
        <v-divider  />
        <v-container>
        <v-data-table  hide-default-footer hide-default-header
    :items="filteredStudents"
    :headers="headers"
    @click:row="checkInStudent">

    <template v-slot:top>
        <v-dialog 
        
          v-model="dialog"
          max-width="500px"
        >
          
          <v-card>
            <v-card-title>
              <span class="text-h5">Check In</span>
            </v-card-title>

            <v-card-text>
              <v-container>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="blue darken-1"
                text
              
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </template>
 
        </v-data-table>
        </v-container>
    </v-container>
    </fullscreen>
</template>

<script>
import studentService from '../services/StudentService'

export default {
    methods: {
    initialize()
{
    this.filteredStudents = this.allStudents;
},     
resetStudents(){
    this.filteredStudents = this.allStudents;
},
filterStudents(letter){
         this.filteredStudents = this.allStudents.filter(student => student.firstName[0].toUpperCase() == letter.toUpperCase());
     },
      toggle() {
          this.fullscreen = !this.fullscreen;
          this.$fullscreen.toggle(document.body, { wrap: false });
          
          
      },
     checkInStudent (item) {
         //add try-catch for issues checking in
         studentService.checkInStudentNoProgram(item);
        this.dialog = true
        setTimeout(() => this.dialog = false, 5000);
      },
  
    },
    watch: {
        dialog (val) {
        val || this.close()
      },
    },
    computed: {
        letters() {
            let letters = []
            for(let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {letters.push(String.fromCharCode([i]))}
            return letters;
        },
        allStudents: function() {
            return this.$store.state.studentList;
        }
    },
    created() {
        this.initialize();
    },
     data() {
      return {
          filteredStudents: this.allStudents,
          dialog: false,
        fullscreen: false,
        headers: [
        {text: 'Name', value: 'firstName'},
         {text: 'Last Name', value: 'lastName'},
        ],
      }
     }
}
</script>

<style>
    .letters {
        margin: 5px;
        
    }
.checkIn{
    margin-top: -5%;
    margin-left: -5%;
}

 
</style>