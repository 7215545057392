<template>
  <v-container>
    <v-data-table
      class="membershipDataTable"
      :items="this.$attrs.students"
      :headers="headers"
    >
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" dark @click="viewStudent(item.studentId)">
          <v-icon> mdi-eye </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row>
      <v-spacer />
      <v-dialog
        @click:outside="destroyAddStudentForm()"
        v-model="addStudentDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="newEntryBtn"
            @click="addStudentDialog = true"
          >
            Add Student
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="destroyAddStudentForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add Student</v-toolbar-title>
            <v-spacer></v-spacer>
           
          </v-toolbar>
          
          <add-new-student-and-enrollments
            v-if="showAddStudentForm && !parentIsStudentsView"
            :membershipId="this.$props.membershipId"
            @studentAddSuccessful="resetAddStudent"
            @formClose="destroyAddStudentForm()"
            ref="addStudentForm"
          />
          <add-new-membership-and-students-workflow @formClose="destroyAddStudentForm()" v-if="showAddStudentForm && parentIsStudentsView"/>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import AddNewStudentAndEnrollments from './AddNewStudentAndEnrollments.vue';
import AddNewMembershipAndStudentsWorkflow from './AddNewMembershipAndStudentsWorkflow.vue';

export default {
  components: { AddNewStudentAndEnrollments, AddNewMembershipAndStudentsWorkflow },
  name: "studentsTable",
  props: {
        membershipId: [String, Number],
      },
  methods: {
    resetAddStudent() {
      this.destroyAddStudentForm();
      this.setStudents();
    },
    destroyAddStudentForm() {
      setTimeout(() => (this.showAddStudentForm = false), 250);
      this.addStudentDialog = false;
      setTimeout(() => (this.showAddStudentForm = true), 250);
    },
    studentSuccessfullyAdded() {
      this.success = true;
      setTimeout(() => (this.success = false), 5000);
    },
    getMembershipName(membershipId) {
      let studentMembership = this.$store.state.membershipList.find(
        (membership) => membership.membershipId == membershipId
      );

      return studentMembership.membershipName;
    },
    viewStudent(studentId) {
      //think about refactoring to use already received data, reduce calls to db
      let id = Number(studentId);
      this.$router.push({ name: "Student View", params: { id } });
    },
    
  },
  data: function () {
    return {
      showAddStudentForm: true,
      addStudentDialog: false,
      success: false,
      dialogDelete: false,
      dateFormat: "MM/DD/YYYY",
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Preferred Name", value: "preferredName" },
        { text: "Last Name", value: "lastName" },
        {
          text: "Membership Name",
          value: "membershipId",
          formatter: (x) => (x ? this.getMembershipName(x) : null),
        },
        {
          text: "Birth Date",
          value: "birthDate",
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null),
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    parentIsStudentsView(){
      return this.$parent.$options.name == "studentsView";
    },
  },
};
</script>

<style>
.membershipDataTable {
  margin-top: 3%;
  margin-left: 4%;
}
</style>