<template>
  <v-form class="editEnrollmentForm">
    <v-alert
      :value="alert"
      border="top"
      type="error"
      transition="scale-transition"
    >
      {{ this.errorMsg }}
    </v-alert>

    <v-row>
      <v-col>
        <v-text-field readonly v-model="programName" label="Program">
        </v-text-field>
      </v-col>

      <v-col>
        <v-combobox
          :value="getRankName(this.enrollmentEdit.rankId)"
          :items="this.$store.state.rankList"
          label="Rank"
          item-text="name"
          item-value="rankId"
          @change="setNewRank($event)"
        >
        </v-combobox>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-menu
          ref="startDateSelector"
          v-model="startDateSelector"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedStartDate"
              label="Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            class="enrollmentDatePicker"
            v-model="enrollmentEdit.startDate"
            @change="setStartDate"
            @input="startDateSelector = false"
            :active-picker.sync="activePicker"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col>
        <v-menu
          ref="endDateSelector"
          v-model="endDateSelector"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              @click:clear="clearEndDate()"
              :value="formattedEndDate"
              label="End Date"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="enrollmentEdit.endDate"
            @change="setEndDate()"
            @input="endDateSelector = false"
            :active-picker.sync="activePicker"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";
import enrollmentService from "../services/EnrollmentService";

export default {
  props: {
    enrollment: Object,
  },
  data() {
    return {
      endDateCheck: Object,
      dateCheck: new Date("0001-01-01"),
      programName: "",
      formattedStartDate: "",
      formattedEndDate: "",
      dateFormat: "MM/DD/YYYY",
      dateFormat2: "YYYY-MM-DD",
      startDateSelector: false,
      endDateSelector: false,
      activePicker: "",
      enrollmentEdit: Object,
      enrollmentBeforeEdit: Object,
      alert: false,
      errorMsg: "",
      endDateSet: false,
      // enrollment: {},
      // selectedEnrollment: "",
      // enrollmentId: this.$route.params.enrollmentId,
    };
  },
  methods: {
    
       clearEndDate(){
      this.endDateSet = false;
      this.enrollmentEdit.active = true;
      this.formattedEndDate = null;
      const current = new Date();
      const today = moment(current).format(this.dateFormat2);
      this.enrollmentEdit.endDate = today;
    },
    editEnrollment() {
      if (!this.endDateSet) {
        this.enrollmentEdit.endDate = "0001-01-01";
      }
      enrollmentService
        .updateEnrollment(this.enrollmentEdit)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("UPDATE_ENROLLMENT", this.enrollmentEdit);
            this.formDialog = false;
            this.$emit("enrollmentEditSuccessful");
          }
        })

        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error updating enrollment. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },
    getProgramName(programId) {
      let program = this.$store.state.programList.find(
        (programSearch) => programSearch.programId == programId
      );
      return program.name;
    },
    setStartDate() {
      this.formattedStartDate = moment(this.enrollmentEdit.startDate).format(
        this.dateFormat
      );
    },
    setEndDate() {
      this.endDateSet = true;
      this.enrollmentEdit.active = false;
      this.formattedEndDate = moment(this.enrollmentEdit.endDate).format(
        this.dateFormat
      );
    },
    formatDate(date) {
      return moment(date).format(this.dateFormat);
    },
    getRankName(rankId) {
      let rank = this.$store.state.rankList.find(
        (rankSearch) => rankSearch.rankId == rankId
      );
      return rank.name;
    },
    setNewRank(event) {
      this.enrollmentEdit.rankId = event.rankId;
    },
  },
  created() {
    this.enrollmentEdit = Object.assign({}, this.enrollment);
    this.enrollmentBeforeEdit = Object.assign({}, this.enrollment);
    const current = new Date();
    const today = moment(current).format(this.dateFormat2);
    this.enrollmentEdit.endDate = today;
    // this.enrollment = this.$store.state.enrollmentList.find(
    //   (enrollment) => enrollment.enrollmentId == this.enrollmentId
    // );
    this.programName = this.getProgramName(this.enrollment.programId);
    this.formattedStartDate = moment(this.enrollment.startDate).format(
      this.dateFormat
    );
    this.endDateCheck = new Date(this.enrollment.endDate);
    if (this.enrollmentBeforeEdit.endDate.toString() != "0001-01-01") {
      this.formattedEndDate = moment(this.enrollmentBeforeEdit.endDate).format(
        this.dateFormat
      );
    } else {
      this.formattedEndDate = "";
    }
  },
  // beforeMount() {
  //   this.enrollmentEdit = Object.assign({}, this.enrollment);
  //   this.enrollmentBeforeEdit = Object.assign({}, this.enrollment);
  //   const current = new Date();
  //     const today = moment(current).format(this.dateFormat2);
  //   this.enrollmentEdit.endDate = today;
  // },
};
</script>

<style>
.editEnrollmentForm {
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 1%;
}
</style>
