<template >
  <v-container>
    <v-alert
      :value="this.addSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Membership Type added successfully.
    </v-alert>
    <v-data-table
      :items="this.$store.state.membershipTypeList"
      item-key="membershipTypeId"
      :headers="this.headers"
    >
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" dark @click="showEditForm(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <delete-prompt
          :item="item"
          :type="type"
          :typeLower="typeLower"
          @deleteConfirm="deleteMembershipType(item.membershipTypeId)"
        />
      </template>
    </v-data-table>
    <v-row>
      <v-spacer />
      <v-dialog
        @click:outside="destroyAddEmailForm()"
        v-model="addMembershipTypeDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="addMembershipTypeDialog = true"
          >
            Add Membership Type
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="destroyAddMembershipTypeForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add Membership Type</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="addMembershipType()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <add-membership-type-form
            v-if="showAddMembershipTypeForm"
            ref="addMembershipTypeForm"
            @membershipTypeAddSuccessful="resetAddMembershipTypeForm()"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
      v-model="editMembershipTypeDialog"
      @click:outside="destroyEditMembershipTypeForm()"
      :retain-focus="false"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="destroyEditAddressForm()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Edit Membership Type</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="updateMembershipType()">
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <edit-membership-type-form
          :membershipType="this.selectedMembershipType"
          ref="editMembershipTypeForm"
          @membershipTypeEditSuccessful="resetEditMembershipTypeForm()"
          :key="destructionVar"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DeletePrompt from "./DeletePrompt.vue";
import EditMembershipTypeForm from "./EditMembershipTypeForm.vue";
import membershipTypeService from "../services/MembershipTypeService";
import AddMembershipTypeForm from "./AddMembershipTypeForm.vue";

export default {
  components: { EditMembershipTypeForm, DeletePrompt, AddMembershipTypeForm },
  created() {
    this.setMembershipTypeList();
  },
  computed: {},
  methods: {
    updateMembershipType(){
this.$refs.editMembershipTypeForm.updateMembershipType();
    },
    showEditForm(item) {
      this.destructionVar++;
      this.selectedMembershipType = item;
      this.editMembershipTypeDialog = true;
    },
    popUpAddSuccess() {
      this.addSuccess = true;
      setTimeout(() => (this.addSuccess = false), 5000);
    },
    resetAddMembershipTypeForm() {
      this.destroyAddMembershipTypeFormWithSuccess();
    },
     resetEditMembershipTypeForm() {
      this.destroyEditMembershipTypeFormWithSuccess();
    },
    destroyEditMembershipTypeFormWithSuccess() {
      this.editMembershipTypeDialog = false;
      this.popUpAddSuccess();
      this.editAddMembershipTypeForm = false;
      setTimeout(() => (this.showEditMembershipTypeForm = true), 250);
    },
    destroyEditMembershipTypeForm() {
      this.editMembershipTypeDialog = false;
      this.showEditMembershipTypeForm = false;
      setTimeout(() => (this.showEditMembershipTypeForm = true), 250);
    },
    destroyAddMembershipTypeForm() {
      this.addMembershipTypeDialog = false;
      this.showAddMembershipTypeForm = false;
      setTimeout(() => (this.showAddMembershipTypeForm = true), 250);
    },
    destroyAddMembershipTypeFormWithSuccess() {
      this.addMembershipTypeDialog = false;
      this.popUpAddSuccess();
      this.showAddMembershipTypeForm = false;
      setTimeout(() => (this.showAddMembershipTypeForm = true), 250);
    },
    addMembershipType() {
      this.$refs.addMembershipTypeForm.addMembershipType();
    },
    setMembershipTypeList() {
      this.membershipTypeList = this.$store.state.membershipTypeList;
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(value);
    },
    deleteMembershipType(id) {
      const idNum = Number(id);
      membershipTypeService
        .deleteMembershipType(idNum)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("DELETE_MEMBERSHIP_TYPE", idNum);
            this.$emit("membershipTypeDeleted");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error deleting membership type. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
        });
    },
  },
  data() {
    return {
      showEditMembershipTypeForm: true,
      selectedMembershipType: Object,
      editSuccess: false,
      editMembershipTypeDialog: false,
      destructionVar: 0,
      addSuccess: false,
      addMembershipTypeDialog: false,
      showAddMembershipTypeForm: true,
      membershipTypeList: [],
      type: "Membership Type",
      typeLower: "membership type",
      errorMsg: "",
      headers: [
        {
          text: "Name",
          value: "membershipTypeName",
        },
        {
          text: "Length (Months)",
          value: "membershipTypeLength",
        },
        {
          text: "Cost Per Month",
          value: "membershipTypeCostPerMonth",
          formatter: (x) => (x ? this.formatCurrency(x) : null),
        },
        {
          text: "Membership Total",
          value: "membershipCostTotal",
          formatter: (x) => (x ? this.formatCurrency(x) : null),
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style>
</style>