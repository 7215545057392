import axios from 'axios';
import store from '../store/index';

const firebaseToken = store.state.token;

const http = axios.create({
    // baseURL: "http://192.168.0.113:8080"
        // baseURL: "http://localhost:8080",
    baseURL: "https://taekwondo-school-check-in.uc.r.appspot.com/"
  });

export default {

    getEnrollments(){
        return http.get('/enrollments', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
    
    updateEnrollment(editedEnrollment){
        return http.put('/enrollments', editedEnrollment, {
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': firebaseToken
            }
        });
    },

    addEnrollment(newEnrollment){
        return http.post('/enrollments', newEnrollment, {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': firebaseToken
            }
        });
    }
   
}