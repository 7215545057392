import axios from 'axios';
import store from '../store/index';

const firebaseToken = store.state.token;

const http = axios.create({
    // baseURL: "http://192.168.0.113:8080"
        // baseURL: "http://localhost:8080",
    baseURL: "https://taekwondo-school-check-in.uc.r.appspot.com/"
  });

export default {

    getMembershipTypes(){
        return http.get('/membership-types', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
    
    updateMembershipType(editedMembershipType){
        return http.put('/membership-types', editedMembershipType, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
    deleteMembershipType(id){
        return http.delete(`/membership-types/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },

    addMembershipType(newMembershipType){
        return http.post('/membership-types', newMembershipType, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    }
}