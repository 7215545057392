import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
import Home from '@/views/HomeView.vue'
import Programs from '@/views/ProgramsView.vue'
import Memberships from '@/views/MembershipsView.vue'
import Students from '@/views/StudentsView.vue'
import StudentView from '@/views/StudentView.vue'
import CheckInView from '@/views/CheckInView.vue'
import EditStudent from '@/views/EditStudent.vue'
import EditEnrollment from '@/views/EditEnrollment.vue'
import MembershipView from '@/views/ViewMembership.vue'
import ProgramView from '@/views/ViewProgram.vue'
import LoginScreen from '@/views/LoginScreen.vue'


Vue.use(Router)

/**
 * The Vue Router is used to "direct" the browser to render a specific view component
 * inside of App.vue depending on the URL.
 *
 * It also is used to detect whether or not a route requires the user to have first authenticated.
 * If the user has not yet authenticated (and needs to) they are redirected to /login
 * If they have (or don't need to) they're allowed to go about their way.
 */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      
      path: '/login',
      name: 'Login',
      component: LoginScreen, 
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/',
      component: Home,
      requiresAuth: true
    },
    {
      path: '',
      component: Home,
      requiresAuth: true
    },
    {
      path:'/programs',
      name: 'Programs',
      component: Programs,
      meta: {
        requiresAuth: true
      }
    },
    {
      path:'/memberships',
      name: 'Memberships',
      component: Memberships,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/students',
      name: 'Students',
      component: Students,
      meta: {
        requiresAuth: true
      }
    },
    {
      path:'/students/:id',
      name: 'Student View',
      component: StudentView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/check-in',
      name: 'Check-In',
      component: CheckInView,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/editStudent/:id',
      name: 'Edit Student',
      component: EditStudent,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/editEnrollment/:enrollmentId',
      name: 'Edit Enrollment',
      component: EditEnrollment,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/memberships/:membershipId',
      name: 'View Membership',
      component: MembershipView,
      meta: {
        requiresAuth: true}

      },
      {
        path: '/programs/:programId',
        name: 'View Program',
        component: ProgramView,
        meta: {
          requiresAuth: true
        }
      }
  ]
})

router.beforeEach((to, from, next) => {
  // Determine if the route requires Authentication
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  // If it does and they are not logged in, send the user to "/login"
  if (requiresAuth && store.state.token === '') {
    next("/login");
  } else {
    // Else let them go to their next destination
    next();
  }
});

export default router;
