<template>
  <v-container>
     <v-alert
      :value="this.addSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Phone Number added successfully.
    </v-alert>
    <v-alert
      :value="this.editSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Phone Number edited successfully.
    </v-alert>
    <v-data-table
      :items="this.phoneNumbers"
      :headers="this.phoneNumberHeaders"
      class="membershipDataTable"
    >
      <template v-slot:[`item.actions`]="{ item }">
      <v-btn color="primary" @click="showEditForm(item)" class="actionBtn" >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
        
        <delete-prompt
          :item="item"
          :type="type"
          :typeLower="typeLower"
          v-on:deleteConfirm="deletePhoneNumber(item.phoneNumberId)"
        />
      </template>
    </v-data-table>
        <v-row>
      <v-spacer />
      <v-dialog 
      @click:outside="destroyAddPhoneNumberForm()" 
      v-model="addPhoneNumberDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="newEntryBtn"
            @click="addPhoneNumberDialog = true"
          >
            Add Phone Number
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="destroyAddPhoneNumberForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add Phone Number</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="addPhoneNumber()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <add-phone-number-form
            v-if="showAddPhoneNumberForm"
            :membershipId="this.membershipId"
            @phoneNumberAddSuccessful="resetAddPhoneNumber"
            ref="addPhoneNumberForm"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="editPhoneNumberDialog" @click:outside="destroyEditPhoneNumberForm()">
    
    <v-card >
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="destroyEditEmailForm()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit Phone Number</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="editPhoneNumber()"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <edit-phone-number-form :phoneNumber="this.selectedPhoneNumber" :key="destructionVar" ref="editPhoneNumberForm" @phoneNumberEditSuccessful="destroyEditPhoneNumberFormWithSuccess()"/>
        </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import DeletePrompt from "./DeletePrompt.vue";
import EditPhoneNumberForm from "./EditPhoneNumberForm.vue";
import phoneNumberService from "../services/PhoneNumberService";
import AddPhoneNumberForm from "./AddPhoneNumberForm.vue"

export default {
  components: { EditPhoneNumberForm, DeletePrompt, AddPhoneNumberForm },
  beforeMount(){
    this.setPhoneNumbers();
  },
  methods: {
    showEditForm(item) {
      this.destructionVar++;
      this.selectedPhoneNumber = item;
      this.editPhoneNumberDialog = true;
    },
    popUpAddSuccess() {
      this.addSuccess = true;
      setTimeout(() => (this.addSuccess = false), 5000);
    },
    popUpEditSuccess(){
      this.editSuccess = true;
      setTimeout(() => (this.editSuccess = false), 5000);
    },
    addPhoneNumber() {
      this.$refs.addPhoneNumberForm.addPhoneNumber();
    },
    editPhoneNumber(){
      this.$refs.editPhoneNumberForm.updatePhoneNumber();
    },
    setPhoneNumbers() {
      this.phoneNumbers = this.$store.state.phoneNumberList.filter(
        (phoneNumber) => phoneNumber.membershipId == this.membershipId
      );
    },
    resetAddPhoneNumber() {
      this.destroyAddPhoneNumberFormWithSuccess();
      this.setPhoneNumbers();
    },
    resetEditPhoneNumber(){
      this.destroyEditPhoneNumberFormWithSuccess();
      this.setPhoneNumbers();
    },
    destroyEditPhoneNumberForm() {
      this.editPhoneNumberDialog = false;
      this.showEditPhoneNumberForm = false;
      setTimeout(() => (this.showEditPhoneNumberForm = true), 250);
    },
    destroyEditPhoneNumberFormWithSuccess() {
      this.editPhoneNumberDialog = false;
      this.popUpEditSuccess();
      this.showEditPhoneNumberForm = false;
      setTimeout(() => (this.showEditPhoneNumberForm = true), 250);
    },
    destroyAddPhoneNumberForm() {
      this.addPhoneNumberDialog = false;
      this.showAddPhoneNumberForm = false;
      setTimeout(() => (this.showAddPhoneNumberForm = true), 250);
    },
    destroyAddPhoneNumberFormWithSuccess() {
      this.addPhoneNumberDialog = false;
      this.popUpAddSuccess();
      this.showAddPhoneNumberForm = false;
      setTimeout(() => (this.showAddPhoneNumberForm = true), 250);
    },
    deletePhoneNumber(id) {
      const idNum = Number(id);
      phoneNumberService
        .deletePhoneNumber(idNum)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("DELETE_PHONE_NUMBER", idNum);
            this.setPhoneNumbers();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error deleting phone number. ${error.response.status} - ${error.response.statusText}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
        });
    },
  },
   props: {
    membershipId: [String, Number],
  },
  data() {
    return {
      selectedPhoneNumber: Object,
      destructionVar: 0,
      editSuccess: false,
      showEditPhoneNumberForm: true,
      editPhoneNumberDialog: false,
      addSuccess: false,
      showAddPhoneNumberForm: true,
      addPhoneNumberDialog: false,
      phoneNumbers: [],
      type: "Phone Number",
      typeLower: "phone number",
      phoneNumberHeaders: [
        {
          text: "Owner",
          value: "phoneNumberOwnerName",
          width: "20%",
        },
        {
          text: "Type",
          value: "phoneNumberType",
          width: "15%",
        },
        {
          text: "Phone Number",
          value: "phoneNumber",
          width: "auto",
        },
        { text: "Actions", value: "actions", sortable: false, width: "20%" },
      ],
    };
  },
};
</script>

<style>
.membershipDataTable {
  margin-top: 3%;
  margin-left: 4%;
}
</style>