import axios from 'axios';
import store from '../store/index';

const firebaseToken = store.state.token;

const http = axios.create({
    // baseURL: "http://192.168.0.113:8080"
    // baseURL: "http://localhost:8080",
    baseURL: "https://taekwondo-school-check-in.uc.r.appspot.com/"
  });

export default {

    getAddresses(){
        return http.get('/addresses', {
            headers: {
                'Content-Type':  'application/json',
                'Authorization': firebaseToken
            }
        });
    },
    
    updateAddress(editedAddress){
        return http.put('/addresses', {
            headers: {
                'Content-Type':  'application/json',
                'Authorization': firebaseToken
            }
        }, editedAddress);
    },

    deleteAddress(idNum){
        return http.delete(`/addresses/${idNum}`,{
            headers: {
                'Content-Type':  'application/json',
                'Authorization': firebaseToken
            }
        });
    },

    addAddress(newAddress){
        return http.post('addresses', newAddress,{
            headers: {
                'Content-Type':  'application/json',
                'Authorization': firebaseToken
            }
        });
    }
   
}