<template>
      <v-form class="dataForm">
        <v-row>
          <v-col>
            <v-text-field
              label="E-Mail Owner Name"
              v-model="newEmail.emailAddressOwnerName"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-combobox
              label="E-Mail Type"
              v-model="newEmail.emailAddressType"
              :items="this.emailTypeList"
            ></v-combobox>
          </v-col>

          <v-col>
            <v-text-field
              label="E-Mail"
              v-model="newEmail.emailAddress"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

</template>

<script>
import emailService from '../services/EmailAddressService'

export default {
  data() {
    return {
      errorMsg: "",
      formDialog: false,
      emailTypeList: ["Personal", "Work", "Other"],
      newEmail: {
        membershipId: this.membershipId,
        emailAddressOwnerName: "",
        emailAddressType: "",
        emailAddress: "",
      },
      cleanEmail: {
        membershipId: this.membershipId,
        emailAddressOwnerName: "",
        emailAddressType: "",
        emailAddress: "",
      },
    };
  },
  props: {
    membershipId: [String, Number],
  },
  methods: {
    resetAddEmailForm() {
      this.formDialog = false;
      this.newEmail = Object.assign({}, this.cleanEmail);
    },
    addEmail() {
      emailService
        .addEmail(this.newEmail)
        .then((response) => {
          if (response.status === 200) {
              this.newEmail.emailAddressId = response.data;
            this.$store.commit("ADD_EMAIL", this.newEmail);

           this.resetAddEmailForm();
            this.$emit("emailAddSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error adding email. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },
  },
  beforeMount() {
    this.newEmail = Object.assign({}, this.cleanEmail);
  },
};
</script>

<style>
.dataForm {
  padding: 1.5%;
}

.newEntryBtn {
  margin: 0.5%;
}
</style>