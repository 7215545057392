<template>
  
     <v-container>
      <v-alert
        :value="alert"
        border="top"
        type="error"
        transition="scale-transition"
      >
        {{ this.errorMsg }}
      </v-alert>
      <v-stepper v-model="stepTracker" elevation="0">
        <v-stepper-header>
          <v-stepper-step :complete="stepTracker > 1" step="1">
            Existing Membership Check
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="stepTracker > 2" step="2">
            New Student Form
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="stepTracker > 3" step="3">
            New Student Enrollments
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card elevation="0">
              <v-alert type="success" :value="membershipCreatedSuccess"
                >Membership created successfully, please add Membership information or click "Continue" below
                to add student(s).</v-alert
              >
              <p class="membershipPrompt">
                Is this student part of an existing membership?
              </p>

              <v-container
                class="promptAnswers"
                
              >
                <v-radio-group
                  :disabled="disableMembershipRadio"
                  v-model="existingMembershipSelectedRadio"
                  @change="setExistingMembershipSelection($event)"
                >
                  <v-row>
                    <v-spacer />

                    <v-radio class="promptOption" label="No" value="false">
                    </v-radio>

                    <v-radio
                      class="promptOption"
                      label="Yes"
                      value="true"
                    ></v-radio>

                    <v-spacer />
                  </v-row>
                </v-radio-group>
                <v-container v-if="promptSet" >
                  <v-container v-if="!membershipCreatedSuccess">
                  <v-combobox
                    v-if="existingMembershipSelected"
                    :items="this.$store.state.membershipList"
                    label="Membership/Family"
                    item-text="membershipName"
                    item-value="membershipId"
                    @change="setExistingMembershipId($event)"
                  >
                  </v-combobox>
                  <add-membership-form
                    class="newMembershipForm"
                    @hideForm="newMembershipCreated"
                    v-else
                  />
                  </v-container>

                  <v-container v-if="membershipCreatedSuccess" class="text-center">
                   
                    <v-row>
                      <v-col>
                         <v-spacer/>
                      </v-col>
                      <v-col>
                      <add-address-form :membershipId="this.membershipIdSelection"  />
                    <v-spacer/>
                      
                      <add-phone-number-form :membershipId="this.membershipIdSelection" />
                    <v-spacer/>
                      <add-email-form :membershipId="this.membershipIdSelection" />
                      </v-col>
                       <v-col>
                    <v-spacer/>
                    </v-col>
                    </v-row>
                   
                  </v-container>
                  
                </v-container>
              </v-container>
            </v-card>
            <v-row>
              <v-spacer /> 
              <v-btn
                class="stepperBtn"
                color="primary"
                @click="stepTracker = 2"
                :disabled="!membershipIdSet"
              >
                Continue
              </v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card elevation="0" height="max">
              <v-alert
                :value="studentSuccess"
                border="top"
                type="success"
                transition="scale-transition"
              >
                Student created successfully. Please click "Continue" to add
                enrollment(s) or "Add Another Student" to add another student
                within this membership.
              </v-alert>
              <add-student-form
                :membershipId="membershipIdSelection"
                @studentAddSuccessful="lockStudentForm"
                @resetStudentForm="resetStudentForm"
              />
            </v-card>
            <v-row>
              <v-spacer />
              <v-btn @click="resetForm" class="stepperBtn"> Start Over </v-btn>

              <v-btn
                color="primary"
                @click="getStudents()"
                class="stepperBtn"
                :disabled="!studentIdSet"
              >
                Continue
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-card elevation="0">
              <v-alert
                :value="enrollmentSuccess"
                border="top"
                type="success"
                transition="scale-transition"
              >
                Enrollment created successfully. Please click below if you would
                like to add another.
              </v-alert>
              <add-enrollment-form :filteredStudentList="newStudents" @enrollmentAddSuccessful="successfulEnrollment" @resetEnrollmentForm="resetEnrollment"/>
            </v-card>
            <v-row>
              <v-btn @click="resetForm" class="stepperBtn"> Start Over </v-btn>
              <v-spacer/>
              <v-btn @click="formCloseAction()" class="stepperBtn"> Done</v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
     </v-container>
</template>

<script>
import AddStudentForm from "./AddStudentForm.vue";
import AddMembershipForm from "./AddMembershipForm.vue";
import AddEnrollmentForm from "./AddEnrollmentForm.vue"
import AddAddressForm from "./AddAddressForm.vue"
import AddEmailForm from "./AddEmailForm.vue"
import AddPhoneNumberForm from "./AddPhoneNumberForm.vue"

export default {
  components: { AddStudentForm, AddMembershipForm, AddEnrollmentForm, AddAddressForm, AddEmailForm, AddPhoneNumberForm },
  name: "addNewMembershipAndStudentsWorkflow",
  data() {
    return {
      //variables for controlling form
      existingMembershipSelectedRadio: "",
      disableMembershipRadio: false,
      stepTracker: 1,
      formDialog: false,
      promptSet: false,
     
      membershipIdSet: false,
      studentIdSet: false,
      studentFormVisible: true,
      enrollmentForm: true,
      //variables for controlling alerts
      studentSuccess: false,
      alert: false,
      errorMsg: "",
      enrollmentSuccess: false,
      membershipCreatedSuccess: false,
      //data variables
      studentIds: [],
      newStudents: [],
      programRanks: [],
      membershipIdSelection: "",
      existingMembershipSelected: "",
    };
  },
  methods: {
    //UI manipulating methods
    formCloseAction(){
      this.$emit("formClose");
    },
    resetEnrollment() {
      this.enrollmentSuccess = false;
    },
    successfulEnrollment(){
      this.enrollmentSuccess = true;
      setTimeout(
        () => (this.enrollmentSuccess = false),
        3000
      );
    },
    resetStudentForm() {
      this.studentSuccess = false;
    },
    getMembershipName(membershipId) {
      let membership = this.$store.state.membershipList.find(
        (membershipSearch) => membershipSearch.membershipId == membershipId
      );
      return `${membership.membershipName}, ${membership.membershipContactName}`;
    },
    newMembershipCreated(newId) {
      this.membershipIdSelection = newId;
      this.membershipIdSet = true;
      this.membershipCreatedSuccess = true;
      this.disableMembershipRadio = true;
    },
    formCancel() {
      this.resetForm();
      this.$emit("formClose")
    },
    resetForm() {
      this.existingMembershipSelectedRadio = "";
      this.membershipTypeName = "";
      this.studentId = "";
      this.membershipIdSelection = "";
      this.membershipIdSet = false;
      this.studentIdSet = false;
      this.formattedStartDate = "";
      this.promptSet = false;
      this.alert = false;
      this.errorMsg = null;
      this.stepTracker = 1;
      this.studentFormVisible = true;
      this.studentSuccess = false;
      this.enrollmentSuccess = false;
      this.enrollmentFormVisible = true;
      setTimeout(
        () => (this.newMembership = Object.assign({}, this.cleanMembership)),
        250
      );
    },
    lockStudentForm(event) {
      this.studentIds.push(event);
      this.studentIdSet = true;
      this.studentSuccess = true;
      this.studentFormVisible = false;
    },
    //data manipulating methods
    setMembershipTypeName(membershipTypeId) {
      let membershipType = this.$store.state.membershipTypeList.find(
        (membershipTypeSearch) =>
          membershipTypeSearch.membershipTypeId == membershipTypeId
      );

      this.membershipTypeName = membershipType.membershipTypeName;
    },
    setExistingMembershipSelection(event) {
      this.promptSet = true;
      if (event == "true") {
        this.existingMembershipSelected = true;
      } else {
        this.existingMembershipSelected = false;
      }
    },
    setExistingMembershipId(event) {
      this.membershipIdSelection = event.membershipId;
      this.membershipIdSet = true;
    },
     getStudents(){
        this.$store.state.studentList.forEach((student) => {
            this.studentIds.forEach((studentId) =>
            {
            if (student.studentId == studentId){
                this.newStudents.push(student);
              }
            }
             
            )
        });
        this.stepTracker = 3;
    },
  },
  
  computed: {
    parentIsMembershipView() {
      return this.$parent.$parent.$options.name == "membershipView";
    },
  },
};
</script>

<style>
.membershipPrompt {
  text-align: center;
  font-size: 125%;
}

.promptAnswers {
  padding-top: 1%;
}

.promptOption {
  padding: 0.5%;
  padding-left: 1%;
  padding-right: 1%;
}

.stepperBtn {
  margin: 1%;
  padding-right: 0.5%;
}

.saveEnrollmentBtn {
  margin: 1%;
}
</style>