<template>
          <v-form class="dataForm" >
             <v-alert
        :value="alert"
        border="top"
        type="error"
        transition="scale-transition">
        {{this.errorMsg}}
        </v-alert>
      <v-row>
          <v-col>
      <v-text-field label="Street Address 1" v-model="newAddress.streetAddress1"></v-text-field>
          </v-col>
      </v-row>

      <v-row>
          <v-col>
      <v-text-field label="Street Address 2" v-model="newAddress.streetAddress2"></v-text-field>
          </v-col>
      </v-row>

      <v-row>
          <v-col>
      <v-text-field label="City" v-model="newAddress.city"></v-text-field>
          </v-col>
          <v-col>
      <v-combobox label="State" v-model="newAddress.stateCode" :items="this.states"/>
          </v-col>
          <v-col>
      <v-text-field label="Zip Code" v-model="newAddress.postalCode"></v-text-field>
          </v-col>
      </v-row>
  </v-form>
  
</template>

<script>
import addressService from "../services/AddressService"

export default {
    props: {
        membershipId: [String, Number],
    },
  methods: {
    
     addAddress() {
      addressService
        .addAddress(this.newAddress)
        .then((response) => {
          if (response.status === 200) {
              this.newAddress.addressId = response.data;
            this.$store.commit("ADD_ADDRESS", this.newAddress);
            this.$emit("addressAddSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error adding address. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },
   
  },
  beforeMount() {
    this.newAddress = Object.assign({}, this.cleanAddress);
  },
    data() {
        return {
          showAddAddress: true,
          errorMsg: "",
          alert: false,
          formDialog: false,
            states: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"],
            newAddress: {
                membershipId: this.membershipId,
                streetAddress1: "",
                streetAddress2: "",
                city: "",
                stateCode: "",
                postalCode: ""
                },
                cleanAddress: {
                membershipId: this.membershipId,
                streetAddress1: "",
                streetAddress2: "",
                city: "",
                stateCode: "",
                postalCode: ""
                },
            }
        },
}
</script>

<style>
.dataForm {
  padding: 1.5%;
}

.newEntryBtn {
  margin: 0.5%;
}
</style>