<template>
  <v-container>
    <v-alert
      :value="this.addSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Address added successfully.
    </v-alert>
    <v-alert
      :value="this.editSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Address edited successfully.
    </v-alert>
    <v-data-table
      :items="this.addresses"
      :headers="this.addressHeaders"
      class="membershipDataTable"
      item-key="addressId"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="primary"
          dark
          class="actionBtn"
          @click="showEditForm(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <delete-prompt
          :item="item"
          :type="type"
          :typeLower="typeLower"
          v-on:deleteConfirm="deleteAddress(item.addressId)"
        />
      </template>
    </v-data-table>
    <v-row>
      <v-spacer />
      <v-dialog
        @click:outside="destroyAddAddressForm()"
        v-model="addAddressDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="newEntryBtn"
            @click="addAddressDialog = true"
          >
            Add Address
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="destroyAddAddressForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add Address</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="addAddress()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <add-address-form
            v-if="showAddAddressForm"
            :membershipId="this.membershipId"
            @addressAddSuccessful="resetAddAddress()"
            ref="addAddressForm"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
      v-model="editAddressDialog"
      :retain-focus="false"
      @click:outside="destroyEditAddressForm()"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="destroyEditEnrollmentForm()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Edit Address</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="editAddress()"> Save </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <edit-address-form
          :address="this.selectedAddress"
          ref="editAddressForm"
          @addressEditSuccessful="resetEditAddress()"
          :key="destructionVar"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DeletePrompt from "./DeletePrompt.vue";
import EditAddressForm from "./EditAddressForm.vue";
import addressService from "../services/AddressService";
import AddAddressForm from "./AddAddressForm.vue";

export default {
  components: { EditAddressForm, DeletePrompt, AddAddressForm },
  props: {
    membershipId: [String, Number],
  },
  beforeMount() {
    this.setAddresses();
  },
  methods: {
    showEditForm(item) {
      this.destructionVar++;
      this.selectedAddress = item;
      this.editAddressDialog = true;
    },
    popUpAddSuccess() {
      this.addSuccess = true;
      setTimeout(() => (this.addSuccess = false), 5000);
    },
    popUpEditSuccess() {
      this.editSuccess = true;
      setTimeout(() => (this.editSuccess = false), 5000);
    },
    editAddress() {
      this.$refs.editAddressForm.editAddress();
    },
    addAddress() {
      this.$refs.addAddressForm.addAddress();
    },
    setAddresses() {
      this.addresses = this.$store.state.addressList.filter(
        (address) => address.membershipId == this.membershipId
      );
    },
    resetAddAddress() {
      this.destroyAddAddressFormWithSuccess();
      this.setAddresses();
    },
    resetEditAddress() {
      this.destroyEditAddressFormWithSuccess();
      this.setAddresses();
    },
    destroyAddAddressFormWithSuccess() {
      this.addAddressDialog = false;
      this.popUpAddSuccess();
      this.showAddAddressForm = false;
      setTimeout(() => (this.showAddAddressForm = true), 250);
    },
    destroyAddAddressForm() {
      this.addAddressDialog = false;
      this.showAddAddressForm = false;
      setTimeout(() => (this.showAddAddressForm = true), 250);
    },
    destroyEditAddressForm() {
      this.selectedAddress = new Object();
      this.editAddressDialog = false;
      this.showEditAddressForm = false;
      setTimeout(() => (this.showEditAddressForm = true), 250);
    },
    destroyEditAddressFormWithSuccess() {
      this.selectedAddress = new Object();
      this.editAddressDialog = false;
      this.popUpEditSuccess();
      this.showEditAddressForm = false;
      setTimeout(() => (this.showEditAddressForm = true), 250);
    },
    deleteAddress(id) {
      const idNum = Number(id);
      addressService
        .deleteAddress(idNum)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("DELETE_ADDRESS", idNum);
            this.setAddresses();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error deleting address. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
        });
    },
  },
  data() {
    return {
      destructionVar: 1,
      selectedAddress: Object,
      editSuccess: false,
      showEditAddressForm: true,
      editAddressDialog: false,
      addSuccess: false,
      showAddAddressForm: true,
      addAddressDialog: false,
      addresses: [],
      errorMsg: "",
      type: "Address",
      typeLower: "address",
      addressHeaders: [
        {
          text: "Street Address 1",
          value: "streetAddress1",
          width: "16%",
        },
        {
          text: "Street Address 2",
          value: "streetAddress2",
          width: "12%",
        },
        {
          text: "City",
          value: "city",
          width: "14%",
        },
        {
          text: "State",
          value: "stateCode",
          width: "10%",
        },
        {
          text: "Zip Code",
          value: "postalCode",
          width: "10%",
        },
        { text: "Actions", value: "actions", sortable: false, width: "16%" },
      ],
    };
  },
};
</script>

<style>
.membershipDataTable {
  margin-top: 3%;
  margin-left: 4%;
}
</style>