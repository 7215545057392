<template>
  <v-container>
    <v-row>
      <v-col>
        <v-spacer />
      </v-col>
      <v-col>
        <v-dialog v-model="editMembershipDialog" @click:outside="destroyEditMembershipForm()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="editBtn"
              @click="showEditForm()"
            >
              Edit Membership
            </v-btn>
          </template>
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="destroyEditMembershipForm()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Edit Membership</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="updateMembership()"> Save </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <edit-membership-form
            :key="destructionVar"
            ref="editMembershipForm"
              :membership="this.membership"
              @membershipUpdateSuccessful="ensureBubbleUp()"
            />
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
   
    <v-row>
      <v-col>
        <h1>Membership: {{ this.membership.membershipName }}</h1>
      </v-col>
      <v-col>
        <h1>Type: {{ membershipTypeName }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Start Date: {{ formatDate(this.membership.startDate) }}</h2>
      </v-col>
      <v-col>
        <h2 v-if="!this.membership.active">
          End Date: {{ formatDate(this.membership.endDate) }}
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditMembershipForm from "../components/EditMembershipForm.vue";
import moment from "moment";
export default {
  data() {
    return {
      editSuccess: false,
      showEditMembershipForm: true,
      editMembershipDialog: false,
      dateFormat: "MM/DD/YYYY",
      destructionVar: 0,
    };
  },
  computed: {
    membershipTypeName: function () {
      let membershipTypeName = "";
      if (this.membership.membershipType) {
        let membershipTypeFound = this.$store.state.membershipTypeList.find(
          (membershipType) =>
            membershipType.membershipTypeId == this.membership.membershipType
        );
        membershipTypeName = membershipTypeFound.membershipTypeName;
      }
      return membershipTypeName;
    },
  },
  methods: {
    showEditForm() {
      this.destructionVar++;
      this.editMembershipDialog = true;
    },
    updateMembership(){
      this.$refs.editMembershipForm.updateMembership();
    },
    popUpEditSuccess(){
      this.editSuccess = true;
      setTimeout(() => (this.editSuccess = false), 5000);
    },
    destroyEditMembershipFormWithSuccess() {
      this.editMembershipDialog = false;
      this.showEditMembershipForm = false;
      setTimeout(() => (this.showEditPhoneNumberForm = true), 250);
    },
    destroyEditMembershipForm() {
      this.editMembershipDialog = false;
      this.showEditMembershipForm = false;
      setTimeout(() => (this.showEditPhoneNumberForm = true), 250);
    },
    formatDate(x) {
      return moment(x).format(this.dateFormat);
    },
    ensureBubbleUp() {
      this.$emit("membershipUpdateSuccessful");
    },
  },
  props: {
    membership: Object,
  },
  components: {
    EditMembershipForm,
  },
};
</script>

<style>
</style>