<template>

      <v-form v-model="valid" id="editStudentForm">
        <v-row>
          <v-col>
            <v-text-field
              v-model="studentEdit.firstName"
              label="First Name"
              required
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="studentEdit.lastName"
              label="Last Name"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="studentEdit.preferredName"
              label="Preferred Name"
              required
            ></v-text-field>
          </v-col>

          <v-col>

            <v-select
            :items="this.genderOptions"
            label="Gender"
              v-model="studentEdit.gender"
              required
          >
          </v-select>
          </v-col>

          <v-col>
            <v-text-field
              v-model="studentEdit.shoeSize"
              label="Shoe Size"
              required
            >

            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-combobox
              :value="getMembershipName(studentEdit.membershipId)"
              :items="this.$store.state.membershipList"
              label="Membership/Family"
              item-text="membershipName"
              item-value="membershipId"
              @change="setNewMembership($event)"
            >
              <template slot="selection">
                {{ getMembershipName(studentEdit.membershipId) }}
              </template>
              <template v-slot:item="{ item }">
                {{ getMembershipName(item.membershipId) }}
              </template>
            </v-combobox>
          </v-col>

          <v-col>
            <v-menu
              ref="birthdaySelector"
              v-model="birthdaySelector"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formattedBirthDate"
                  label="Birthday"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="birthDate"
                @change="setBirthDate"
                @input="birthdaySelector = false"
                :active-picker.sync="activePicker"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              :items="beltSizes"
              label="Belt Size"
              v-model="studentEdit.beltSize"
            >
            </v-select>
            <v-img
              contain
              aspect-ratio="1"
              max-height="300"
              max-width="200"
              v-if="this.tempUrl"
              :src="this.tempUrl"
            />
            <v-btn class="mb-6 photoBtn" v-on:click="click1"
              >Choose a Photo</v-btn
            >
            <input
              type="file"
              ref="input1"
              style="display: none"
              @change="previewImage"
              accept="image/*"
            />
          </v-col>
          <v-col>
            <v-select
              :items="shirtSizes"
              label="Shirt Size"
              v-model="studentEdit.shirtSize"
            >
            </v-select>
          </v-col>
        </v-row>
        
      </v-form>

</template>

<script>
import moment from "moment";
import studentService from "../services/StudentService";
import firebase from "firebase/compat/app";

export default {
  components: {  },
  props: {
    student: Object,
  },
  data() {
    return {
      genderOptions: [
        "Male",
        "Female",
        "Non-Binary",
        "Gender Fluid",
        "Non-Disclosed",
      ],
      success: false,
      editEnrollmentDialog: false,
      errorMsg: "",
      imageData: null,
      img1: null,
      tempUrl: "",
      dateCheck: new Date("0001-01-01"),
      studentEdit: "",
      studentBeforeEdit: "",
      editStudentDialog: false,
      formattedBirthDate: "",
      activePicker: null,
      birthdaySelector: false,
      valid: false,
      birthDate: "",
      selectedEnrollment: "",
      dateFormat: "MM/DD/YYYY",
      studentId: this.$route.params.id,
      beltSizes: [
        "00000",
        "0000",
        "000",
        "00",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
      ],
      shirtSizes: [
        "Child XS",
        "Child S",
        "Child M",
        "Child L",
        "Child XL",
        "Adult XS",
        "Adult S",
        "Adult M",
        "Adult L",
        "Adult XL",
        "Adult XXL",
        "Adult XXXL",
      ],
    };
  },
  methods: {
    click1() {
      this.$refs.input1.click();
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.img1 = null;
      this.imageData = event.target.files[0];
      const imageName =this.imageData.name;
      const imageNameSlice = imageName.slice(0, imageName.length - 4);
      this.studentEdit.imageName = `${imageNameSlice}_200x200.jpg`;
      this.tempUrl = URL.createObjectURL(this.imageData);
    },
    onUpload() {
      if (this.imageData != null) {
        this.img1 = null;
        const storageRef = firebase
          .storage()
          .ref(`${this.imageData.name}`)
          .put(this.imageData);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            this.errorMsg = error.message;
          },
          () => {
            this.uploadValue = 100;
          }
        );
      }
    },
    resetForm() {
      this.editStudentDialog = false;
      setTimeout(
        () => (this.studentEdit = Object.assign({}, this.studentBeforeEdit)),
        250
      );
    },
    setNewMembership(event){
      this.studentEdit.membershipId = event.membershipId;
    },
    popUpSuccess() {
      this.success = true;
      setTimeout(() => (this.success = false), 5000);
    },
    endDateFormatter(date) {
      let endDateCheck = new Date(date);
      if (endDateCheck.getDate() !== this.dateCheck.getDate()) {
        return moment(date).format(this.dateFormat);
      } else {
        return null;
      }
    },
    updateStudent() {
      studentService
        .updateStudent(this.$props.student.studentId, this.studentEdit)
        .then((response) => {
          if (response.status === 200) {
            this.onUpload();
            this.$store.commit("UPDATE_STUDENT", this.studentEdit);
            this.$emit("studentEditSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error updating student. ${error.response.status} - ${error.response.statusText}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
        })
        .finally(() => {
          this.editStudentDialog = false;
        });
    },
    setBirthDate() {
      this.formattedBirthDate = moment(this.birthDate).format(this.dateFormat);
      this.studentEdit.birthDate = this.birthDate;
    },
    formatDate(date) {
      return moment(date).format(this.dateFormat);
    },
    getProgramName(programId) {
      let program = this.$store.state.programList.find(
        (programSearch) => programSearch.programId == programId
      );
      return program.name;
    },
    getRankName(rankId) {
      let rank = this.$store.state.rankList.find(
        (rankSearch) => rankSearch.rankId == rankId
      );
      return rank.name;
    },
    getMembershipName(membershipId) {
      let membership = this.$store.state.membershipList.find(
        (membershipSearch) => membershipSearch.membershipId == membershipId
      );
      return `${membership.membershipName}, ${membership.membershipContactName}`;
    },
    determineActive(active) {
      if (active) {
        return "Yes";
      } else {
        return null;
      }
    },
  },
  created() {
    this.birthDate = this.student.birthDate;
    this.formattedBirthDate = moment(this.student.birthDate).format(
      this.dateFormat
    );
  },
  beforeMount() {
    this.studentEdit = Object.assign({}, this.student);
    this.studentBeforeEdit = Object.assign({}, this.student);
  },
  computed: {
    enrollments: function () {
      return this.$store.state.enrollmentList.filter(
        (enrollment) => (enrollment.studentId == this.studentId)
      );
    },
  },
};
</script>

<style>
#editStudentForm {
  padding: 2%;
}

.photoBtn {
  margin-top: 2%;
  margin-left: 4%;
  margin-right: 4%;
}
</style>