<template click:outside="testParent">
  <v-form  class="newMembershipForm" :disabled="disableForm">
    <v-alert
      :value="alert"
      border="top"
      type="error"
      transition="scale-transition"
    >
      {{ this.errorMsg }}
    </v-alert>

    <v-row>
      <v-col>
        <v-text-field
          label="Membership Name"
          v-model="newMembership.membershipName"
        />
      </v-col>

      <v-col>
        <v-text-field
          label="Membership Primary Contact Name"
          v-model="newMembership.membershipContactName"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-menu
          ref="startDateSelector"
          v-model="startDateSelector"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formattedStartDate"
              label="Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newMembership.startDate"
            @change="setStartDate"
            @input="startDateSelector = false"
            :active-picker.sync="activePicker"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-combobox
          v-model="membershipTypeName"
          :items="this.$store.state.membershipTypeList"
          label="Membership Type"
          item-text="membershipTypeName"
          item-value="membershipTypeId"
          @change="setNewMembershipType($event)"
        >
        </v-combobox>
      </v-col>
     
    </v-row>
     <v-row>
        <v-spacer/>
      <v-btn v-if="parentIsStudentsView" @click="addMembership(newMembership)">Save Membership</v-btn>
      </v-row>
  </v-form>
</template>

<script>
import moment from "moment";
import membershipService from "../services/MembershipService";

export default {
  props: {
    membership: Object,
  },
  data() {
    return {
      disableForm: false,
      newMembership: {
        membershipId: "",
        membershipName: "",
        active: true,
        membershipType: "",
        membershipContactName: "",
        startDate: "",
        endDate: "0001-01-01",
      },
      cleanMembership: {
        membershipId: "",
        membershipName: "",
        active: true,
        membershipType: "",
        membershipContactName: "",
        startDate: "",
        endDate: "0001-01-01",
      },
      formDialog: "",
      alert: false,
      errorMsg: "",
      formattedStartDate: "",
      dateFormat: "MM/DD/YYYY",
      dateFormat2: "YYYY-MM-DD",
      startDateSelector: false,
      activePicker: "",
      membershipTypeName: "",
    };
  },
  computed: {
    parentIsStudentsView() {
      return this.$parent.$parent.$parent.$parent.$options.name == "addNewMembershipAndStudentsWorkflow";
    },
  },
  methods: {
    closeMembership() {
      this.$emit("closeMembership");
    },
    addMembership() {
      membershipService
        .addMembership(this.newMembership)
        .then((response) => {
          if (response.status === 200) {
            this.newMembership.membershipId = response.data;
            this.newMembership.endDate = "01/01/0001";
            this.newMembership.active = true;
            this.$store.commit("ADD_MEMBERSHIP", this.newMembership);
            this.disableForm= true;
            this.$emit("addSuccess", response.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error adding membership. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },
    setNewMembershipType(event) {
      this.newMembership.membershipType = event.membershipTypeId;
      this.setMembershipTypeName(event.membershipTypeId);
    },
    resetForm() {
      this.formDialog = false;
      this.membershipTypeName = "";
      this.formattedStartDate = "";
      this.alert = false;
      this.errorMsg = null;
      setTimeout(
        () => (this.newMembership = Object.assign({}, this.cleanMembership)),
        250
      );
    },
    setStartDate() {
      this.formattedStartDate = moment(this.newMembership.startDate).format(
        this.dateFormat
      );
    },
    formatDate(date) {
      return moment(date).format(this.dateFormat);
    },
    setMembershipTypeName(membershipTypeId) {
      let membershipType = this.$store.state.membershipTypeList.find(
        (membershipTypeSearch) =>
          membershipTypeSearch.membershipTypeId == membershipTypeId
      );

      this.membershipTypeName = membershipType.membershipTypeName;
    },
  },
};
</script>

<style>
.newMembershipForm {
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 1%;
}
</style>