<template>
  <v-form class="dataForm">
    <v-alert
      dismissible
      :value="alert"
      border="top"
      type="error"
      transition="scale-transition"
    >
      {{ this.errorMsg }}
    </v-alert>
    <v-row>
      <v-col>
        <v-text-field
          label="Street Address 1"
          v-model="addressEdit.streetAddress1"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Street Address 2"
          v-model="addressEdit.streetAddress2"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field label="City" v-model="addressEdit.city"></v-text-field>
      </v-col>
      <v-col>
        <v-combobox
          label="State"
          v-model="addressEdit.stateCode"
          :items="this.states"
        />
      </v-col>

      <v-col>
        <v-text-field
          label="Zip Code"
          v-model="addressEdit.postalCode"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import addressService from "../services/AddressService";
export default {
  data() {
    return {
      errorMsg: "",
      alert: false,
      addressEdit: Object,
      addressBeforeEdit: Object,
      states: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
    };
  },
  props: {
    address: Object,
  },
  methods: {
    editAddress() {
      addressService
        .updateAddress(this.addressEdit)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("UPDATE_ADDRESS", this.addressEdit);
            this.$emit("addressEditSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error updating address. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        })
        .finally(() => {
          this.editAddressDialog = false;
        });
    },
  },
  beforeMount: function() {
    this.addressEdit = Object.assign({}, this.address);
    this.addressBeforeEdit = Object.assign({}, this.address);
    
  },
};
</script>

<style>
.dataForm {
  padding: 1.5%;
}

.actionBtn {
  margin: 1.5%;
}
</style>