<template>
  <v-container>
     <v-alert
      :value="this.addSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Membership added successfully.
    </v-alert>
    <v-data-table :items="this.$store.state.membershipList" :headers="headers">
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" dark @click="viewMembership(item.membershipId)">
        <v-icon  class >
          mdi-eye
        </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-row>
      <v-spacer />
      <v-dialog  @click:outside="destroyMembershipForm()" v-model="addMembershipDialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on" class="newBtn" @click="addMembershipDialog = true">
            Add Membership
          </v-btn>
        </template>
        <v-card  >
          <v-toolbar dark color="primary">
            <v-toolbar-items>
              <v-btn icon dark>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
            <v-toolbar-title>Add Membership</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="addMembership()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <add-membership-form 
            v-if="showMembershipForm"
            ref="addMembershipForm"
            @closeMembership="destroyMembershipForm"
            @addSuccess="destroyMembershipFormWithSuccess"
          />
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import AddMembershipForm from './AddMembershipForm.vue';
export default {
  components: { AddMembershipForm },
  name: "membershipsTable",
  props: ["membership"],
  data() {
    return {
addMembershipDialog: false,
      showMembershipForm: true,
      addSuccess: false,
    }
    
  },
  methods: {
    popUpAddSuccess() {
      this.addSuccess = true;
      setTimeout(() => (this.addSuccess = false), 5000);
    },
    destroyMembershipFormWithSuccess() {
      this.showMembershipForm = false;
      this.popUpAddSuccess();
      this.addMembershipDialog = false;
      setTimeout(
        () =>
          (this.showMembershipForm = true),
        250
      );
      
      
    },
    destroyMembershipForm() {
      this.showMembershipForm = false;
      this.addMembershipDialog = false;
      setTimeout(
        () =>
          (this.showMembershipForm = true),
        250
      );
      
      
    },
    getMembershipTypeName(membershipTypeId) {
      let membershipType = this.$store.state.membershipTypeList.find(
        (membershipTypeSearch) =>
          membershipTypeSearch.membershipTypeId == membershipTypeId
      );

      return membershipType.membershipTypeName;
    },
    determineActive(active) {
      if (active) {
        return "Yes";
      } else {
        return "No";
      }
    },
    viewMembership(id) {
      let membershipId = Number(id);
      this.$router.push({ name: "View Membership", params: { membershipId } });
    },
    addMembership() {
      this.$refs.addMembershipForm.addMembership();
    }
  },
  computed: {
    headers() {
      return [
        { text: "Membership Name", value: "membershipName" },
        { text: "Contact Name", value: "membershipContactName" },
        {
          text: "Type",
          value: "membershipType",
          formatter: (x) => (x ? this.getMembershipTypeName(x) : ""),
        },
        {
          text: "Active",
          value: "active",
          formatter: (x) => (x ? this.determineActive(x) : "No"),
        },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
};
</script>

<style>
</style>