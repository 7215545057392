<template>
  
      <v-form class="dataForm">
        <v-row>
          <v-col>
            <v-text-field
              label="Phone Number Owner Name"
              v-model="phoneNumberEdit.phoneNumberOwnerName"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-combobox
              label="Phone Number Type"
              v-model="phoneNumberEdit.phoneNumberType"
              :items="this.phoneNumberTypes"
            ></v-combobox>
          </v-col>

          <v-col>
            <v-text-field
              label="Phone Number"
              v-model="phoneNumberEdit.phoneNumber"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
  
</template>

<script>
import phoneNumberService from '../services/PhoneNumberService';
export default {
  data() {
    return {
      phoneNumberTypes: ["Home", "Work", "Cell", "Other"],
      phoneNumberBeforeEdit: Object,
      phoneNumberEdit: Object,
    };
  },
  props: {
    phoneNumber: Object,
  },

  methods: {
    updatePhoneNumber() {
      phoneNumberService
        .updatePhoneNumber(this.phoneNumberEdit)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("UPDATE_PHONE_NUMBER", this.phoneNumberEdit);
            this.$emit("phoneNumberEditSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error updating Membership Type. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    
  },
  },
  beforeMount(){
    this.phoneNumberEdit = Object.assign({}, this.phoneNumber);
    this.phoneNumberBeforeEdit = Object.assign({}, this.phoneNumber);
  }
};
</script>

<style>
.dataForm {
  padding: 1.5%;
}

.actionBtn{
  margin: 1.5%;
}
</style>