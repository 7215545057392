<template>
  <v-container>
      <v-alert
        :value="alert"
        border="top"
        type="error"
        transition="scale-transition"
      >
        {{ this.errorMsg }}
      </v-alert>
      <v-stepper v-model="stepTracker" elevation="0">
        <v-stepper-header>
          <v-stepper-step :complete="stepTracker > 1" step="1">
            New Student Form
          </v-stepper-step>
          <v-divider />
          <v-stepper-step :complete="stepTracker > 2" step="2">
            New Student Enrollments
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card elevation="0">
              <v-alert
                :value="studentSuccess"
                border="top"
                type="success"
                transition="scale-transition"
              >
                Student created successfully. Please click "Continue" to add
                enrollment(s) or "Add Another Student" to add another student
                within this membership.
              </v-alert>
              <add-student-form
                :membershipId="this.$props.membershipId"
                @studentAddSuccessful="lockStudentForm"
                @resetStudentForm="resetStudentForm"
              />
            </v-card>
            <v-row>
              <v-spacer />
              <v-btn @click="resetForm" class="stepperBtn"> Start Over </v-btn>

              <v-btn
                color="primary"
                @click="getStudents()"
                class="stepperBtn"
                :disabled="!studentIdSet"
              >
                Continue
              </v-btn>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card elevation="0">
              <v-alert
                :value="enrollmentSuccess"
                border="top"
                type="success"
                transition="scale-transition"
              >
                Enrollment created successfully. You may add another enrollment, if needed.
              </v-alert>
              <add-enrollment-form :filteredStudentList="newStudents" @enrollmentAddSuccessful="successfulEnrollment" @resetEnrollmentForm="resetEnrollment"/>
            </v-card>
            <v-row>
              <v-btn @click="resetForm" class="stepperBtn"> Start Over </v-btn>
              <v-spacer/>
              <v-btn @click="formCloseAction()" class="stepperBtn"> Done</v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
     </v-container>
</template>

<script>
import AddStudentForm from "./AddStudentForm.vue";
import AddEnrollmentForm from "./AddEnrollmentForm.vue"

export default {
  components: { AddStudentForm, AddEnrollmentForm},
  name: "addNewMembershipAndStudentsWorkflow",
  props: {
        membershipId: [String, Number],
      },
  data() {
    return {
      //variables for controlling form
      stepTracker: 1,
      formDialog: false,
      promptSet: false,
      studentIdSet: false,
      studentFormVisible: true,
      enrollmentForm: true,
      //variables for controlling alerts
      studentSuccess: false,
      alert: false,
      errorMsg: "",
      enrollmentSuccess: false,
      //data variables
      studentIds: [],
      newStudents: [],
      programRanks: [],
    };
  },
  methods: {
    //UI manipulating methods
    formCloseAction(){
      this.$emit("formClose");
    },
    resetEnrollment() {
      this.enrollmentSuccess = false;
    },
    successfulEnrollment(){
      this.enrollmentSuccess = true;
      setTimeout(
        () => (this.enrollmentSuccess = false),
        3000
      );
    },
    resetStudentForm() {
      this.studentSuccess = false;
    },
    formCancel() {
      this.resetForm();
      this.$emit("formClose")
    },
    resetForm() {
      this.studentId = "";
      this.studentIdSet = false;
      this.formattedStartDate = "";
      this.promptSet = false;
      this.alert = false;
      this.errorMsg = null;
      this.stepTracker = 1;
      this.studentFormVisible = true;
      this.studentSuccess = false;
      this.enrollmentSuccess = false;
      this.enrollmentFormVisible = true;
      setTimeout(
        () => (this.newMembership = Object.assign({}, this.cleanMembership)),
        250
      );
    },
    lockStudentForm(event) {
      this.studentIds.push(event);
      this.studentIdSet = true;
      this.studentSuccess = true;
      this.studentFormVisible = false;
      
    },
    //data manipulating methods
     getStudents(){
        this.$store.state.studentList.forEach((student) => {
            this.studentIds.forEach((studentId) =>
            {
            if (student.studentId == studentId){
                this.newStudents.push(student);
              }
            }
             
            )
        });
        this.stepTracker = 2;
    },
  },
  
  computed: {
    parentIsMembershipView() {
      return this.$parent.$parent.$options.name == "membershipView";
    },
  },
};
</script>

<style>

</style>