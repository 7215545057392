<template>
  <v-app v-bind:class="{ checkIn: isCheckIn }">
    <div class="nav-bar" v-if="!isCheckIn && !isLogin">
      <nav-main  class="nav-bar" />
    </div>
    <div class="content-area" v-bind:class="{ checkIn: isCheckIn }" >
      <router-view  />
    </div>
  </v-app>
</template>
<script>
import NavMain from "./components/NavMenu.vue";
import VueFullscreen from "vue-fullscreen";
import Vue from "vue";
Vue.use(VueFullscreen);
export default {
  name: "app",
  components: {
    NavMain,
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    isCheckIn() {
      return this.$route.path == "/check-in";
    },
    isLogin() {
      return this.$route.path == "/login";
    },
  },
  methods: {
    // saveSearchResults(results) {
    //   this.$store.commit('SET_SEARCH_RESULTS', results);
    // }
    
  },
  created() {
  },
};
</script>
<style>
.nav-bar {
  height: 100%;
  position: fixed;
}
.content-area {
  padding-left: 20%;
  padding-right: 5%;
  padding-top: 5%;
}
.checkIn {
  padding-top: 6%;
}
</style>