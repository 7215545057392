<template>
  <v-container>
    <h2>Memberships</h2>
    <memberships-table />
    
    <v-spacer />
    <h2>Membership Types</h2>
    <membership-types-table
     
    />
  </v-container>
</template>

<script>
import MembershipsTable from "../components/MembershipsTable.vue";
import MembershipTypesTable from "../components/MembershipTypesTable.vue";
export default {
  name: "memberships",
  components: { MembershipsTable, MembershipTypesTable},
  data() {
    return {
      
      membershipTypesKey: 0,
    };
  },
  methods: {
    

  },
};
</script>

<style>
.newBtn {
  margin-left: 84.75%;
}

.formCard {
  height: 200%;
}
</style>