import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/*
 * The authorization header is set for axios when you login but what happens when you come back or
 * the page is refreshed. When that happens you need to check for the token in local storage and if it
 * exists you should set the header so that it will be attached to each request
 */
const currentToken = localStorage.getItem('firebaseToken');
const currentUser = JSON.parse(localStorage.getItem('user'));

if (currentToken != null) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${currentToken}`;
}

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    token: currentToken || '',
    user: currentUser || {},
    programList: [],
    membershipList: [],
    studentList: [],
    enrollmentList: [],
    rankList: [],
    addressList: [],
    emailAddressList: [],
    phoneNumberList: [],
    membershipTypeList: [],
    program: {
      programId: "",
      name: "",
      programRanks: [],
    },
    membershipType: {
      membershipTypeId: "",
      membershipTypeName: "",
      membershipTypeLength: "",
      membershipTypeCostPerMonth: "",
      membershipCostTotal: "",
    },
    membership: {
      membershipId: "",
      membershipName: "",
      membershipContactName: "",
      active: "",
      startDate: "",
      endDate: "",
      membershipType: "",
    },
    address: {
      addressId: "",
      membershipId: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      stateCode: "",
      postalCode: "",
    },
    phoneNumber: {
      phoneNumberId: "",
      membershipId: "",
      phoneNumber: "",
      phoneNumberType: "",
      phoneNumberOwnerName: "",
    },
    emailAddress: {
      emailAddressId: "",
      membershipId: "",
      emailAddress: "",
      emailAddressType: "",
      emailAddressOwnerName: "",
    },
    enrollment: {
      enrollmentId: "",
      programId: "",
      studentId: "",
      rankId: "",
      active: "",
      startDate: "",
      endDate: "",
    },
    student: {
      studentId: "",
      membershipId: "",
      firstName: "",
      preferredName: "",
      lastName: "",
      gender: "",
      birthDate: "", 
      shoeSize: "",
      beltSize: "",
      height: "",
      imageName: "",
      shirtSize: "",
    },
    rank: {
      rankId: "",
      name: "",
      programId: ""
    },
  },
  mutations: {
    SET_AUTH_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('firebaseToken', token)
      axios.defaults.headers.common['Authorization'] = token;
    },
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    LOGOUT(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      state.token = '';
      state.user = {};
      axios.defaults.headers.common = {};
    },
    SET_PROGRAM_LIST(state, data) {
      state.programList = data;
    },
    SET_MEMBERSHIP_LIST(state, data){
      state.membershipList = data;
    },
    SET_STUDENT_LIST(state, data){
      state.studentList = data;
    },
    SET_ENROLLMENT_LIST(state, data){
      state.enrollmentList = data;
    },
    SET_ADDRESS_LIST(state,data){
      state.addressList = data;
    },
    SET_EMAIL_ADDRESS_LIST(state,data){
      state.emailAddressList = data;
    },
    SET_PHONE_NUMBER_LIST(state, data){
      state.phoneNumberList = data;
    },
    SET_RANK_LIST(state, data){
      state.rankList = data;
    },
    SET_MEMBERSHIP_TYPE_LIST(state, data){
      state.membershipTypeList = data;
    },
    UPDATE_STUDENT(state, payload){
      const { studentId, membershipId, firstName, preferredName, lastName, gender, birthDate, shoeSize, beltSize, height, imageName, shirtSize } = payload;
    const student = state.studentList.find(s => s.studentId == studentId);
      student.membershipId = membershipId;
      student.firstName = firstName;
      student.preferredName = preferredName;
      student.lastName = lastName;
      student.gender = gender;
      student.birthDate = birthDate;
      student.shoeSize = shoeSize;
      student.beltSize = beltSize;
      student.height = height;
      student.imageName = imageName;
      student.shirtSize = shirtSize;
    },
    UPDATE_ENROLLMENT(state, payload){
      const { enrollmentId, programId, studentId, rankId, startDate, endDate, active } = payload;
      const enrollment = state.enrollmentList.find(e => e.enrollmentId == enrollmentId);
      enrollment.programId = programId;
      enrollment.studentId = studentId;
      enrollment.rankId = rankId;
      enrollment.startDate = startDate;
      enrollment.endDate = endDate;
      enrollment.active = active;
    },
    UPDATE_MEMBERSHIP_TYPE(state, payload){
      const { membershipTypeId, membershipTypeLength, membershipTypeCostPerMonth} = payload;
      const membershipType = state.membershipTypeList.find(mt => mt.membershipTypeId == membershipTypeId);
      membershipType.membershipTypeCostPerMonth = membershipTypeCostPerMonth;
      membershipType.membershipTypeLength = membershipTypeLength;
      membershipType.membershipCostTotal = membershipType.membershipTypeCostPerMonth * membershipType.membershipTypeLength;
    }, 
    UPDATE_MEMBERSHIP(state, payload){
      const { membershipId, membershipName, membershipContactName, active, startDate, endDate, membershipType } = payload;
      const membership = state.membershipList.find(m => m.membershipId == membershipId);
      membership.membershipName = membershipName;
      membership.membershipContactName = membershipContactName;
      membership.active = active;
      membership.startDate = startDate;
      membership.endDate = endDate;
      membership.membershipType = membershipType;
    },
    UPDATE_RANK(state, payload){
      const { rankId, name, programId } = payload;
      const rank = state.rankList.find(r => r.rankId == rankId);
      rank.programId = programId;
      rank.name = name;
    },
    UPDATE_PROGRAM(state, payload){
      const { name, programId } = payload;
      const program = state.programList.find(p => p.programId == programId);
      program.name = name;
    },
    UPDATE_ADDRESS(state, payload){
      const { addressId, streetAddress1, streetAddress2, city, stateCode, postalCode} = payload;
      const address = state.addressList.find(a => a.addressId == addressId);
      address.streetAddress1 = streetAddress1;
      address.streetAddress2 = streetAddress2;
      address.city = city;
      address.stateCode = stateCode;
      address.postalCode = postalCode;
    },
    UPDATE_EMAIL(state, payload){
      const { emailAddressId, emailAddress, emailAddressType, emailAddressOwnerName } = payload;
      const email = state.emailAddressList.find( e => e.emailAddressId == emailAddressId);
      email.emailAddress = emailAddress;
      email.emailAddressType = emailAddressType;
      email.emailAddressOwnerName = emailAddressOwnerName;
    },
    UPDATE_PHONE_NUMBER(state, payload){
      const { phoneNumberId, membershipId, phoneNumber, phoneNumberType, phoneNumberOwnerName } = payload;
      const phone = state.phoneNumberList.find( p => p.phoneNumberId == phoneNumberId);
      phone.membershipId = membershipId;
      phone.phoneNumber = phoneNumber;
      phone.phoneNumberType = phoneNumberType;
      phone.phoneNumberOwnerName = phoneNumberOwnerName;
    },  
    DELETE_ADDRESS(state, payload){
      const index = state.addressList.map((address) => address.addressId).indexOf(payload);
      state.addressList.splice(index, 1);
    },
    DELETE_EMAIL(state, payload){
      const index = state.emailAddressList.map((email) => email.emailAddressId).indexOf(payload);
      state.emailAddressList.splice(index, 1);
    },
    DELETE_PHONE_NUMBER(state, payload){
      const index = state.phoneNumberList.map((phoneNumber) => phoneNumber.phoneNumberId).indexOf(payload);
      state.phoneNumberList.splice(index, 1);
    },
    DELETE_MEMBERSHIP_TYPE(state, payload){
      const membershipsWithType = state.membershipList.filter((m) => m.membershipType == payload);
      membershipsWithType.forEach((m) => m.membershipType = null);
      const index = state.membershipTypeList.map((mt) => mt.membershipTypeId).indexOf(payload);
      state.membershipTypeList.splice(index, 1);
    },
    ADD_PROGRAM(state, payload){
      const newProgram = {
        id: payload.programId,
        name: payload.name
      }
      state.programList.push(newProgram);
    },
    ADD_RANK(state, payload){
      const newRank = {
        rankId: payload.rankId,
        name: payload.name,
      }
      state.rankList.push(newRank);
    },
    ADD_MEMBERSHIP(state, payload){
      const newMembership = {
        membershipId: payload.membershipId,
        membershipName: payload.membershipName,
        membershipContactName: payload.membershipContactName,
        membershipType: payload.membershipType,
        active: payload.active,
        startDate: payload.startDate,
        endDate: payload.endDate
      }
      state.membershipList.push(newMembership);
    },
    ADD_ADDRESS(state, payload){
      const newAddress = {
        addressId: payload.addressId,
        membershipId: payload.membershipId,
        streetAddress1: payload.streetAddress1,
        streetAddress2: payload.streetAddress2,
        city: payload.city,
        stateCode: payload.stateCode,
        postalCode: payload.postalCode
      }
      state.addressList.push(newAddress);
    },
    ADD_EMAIL(state, payload){
      const newEmail = {
        emailAddressId: payload.emailAddressId,
        membershipId: payload.membershipId,
        emailAddressType: payload.emailAddressType,
        emailAddress: payload.emailAddress,
        emailAddressOwnerName: payload.emailAddressOwnerName
      }
      state.emailAddressList.push(newEmail);
    },
    ADD_PHONE_NUMBER(state, payload){
      const newPhoneNumber = {
        phoneNumberId: payload.phoneNumberId,
        membershipId: payload.membershipId,
        phoneNumberType: payload.phoneNumberType,
        phoneNumber: payload.phoneNumber,
        phoneNumberOwnerName: payload.phoneNumberOwnerName
      }
      state.phoneNumberList.push(newPhoneNumber);
    },
    ADD_MEMBERSHIP_TYPE(state, payload){
      const newMembershipType = {
        membershipTypeId: payload.membershipTypeId,
        membershipTypeName: payload.membershipTypeName,
        membershipTypeLength: payload.membershipTypeLength,
        membershipTypeCostPerMonth: payload.membershipTypeCostPerMonth,
        membershipCostTotal: payload.membershipCostTotal
      }
      state.membershipTypeList.push(newMembershipType);
    },
    ADD_STUDENT(state, payload){
      const newStudent = {
        studentId: payload.studentId,
        membershipId: payload.membershipId,
        firstName: payload.firstName,
        preferredName: payload.preferredName,
        lastName: payload.lastName,
        gender: payload.gender,
        birthDate: payload.birthDate,
        shoeSize: payload.shoeSize,
        beltSize: payload.beltSize,
        height: payload.height,
        imageName: payload.imageName,
        shirtSize: payload.shirtSize

      }
      state.studentList.push(newStudent);
    },
    ADD_ENROLLMENT(state, payload){
      const newEnrollment = {
        enrollmentId: payload.enrollmentId,
      programId: payload.programId,
      studentId: payload.studentId,
      rankId: payload.rankId,
      active: payload.active,
      startDate: payload.startDate,
      endDate: payload.endDate,
      }
      state.enrollmentList.push(newEnrollment);
    }
    

  }
})