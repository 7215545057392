<template >
  <v-container>
    <v-alert
      :value="this.editSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Program edited successfully.
    </v-alert>
    <v-row>
      <h1 class="programHeader">Program: {{ this.program.name }}</h1>
      <v-spacer />
      <edit-program-form
        :program="this.program"
        @programUpdateSuccessful="popUpEditSuccess()"
      />
    </v-row>
    <h2>Students:</h2>
    <program-students-table :programId="programId" />

    <h2>Ranks:</h2>
    <rank-table :programId="this.programId" />
    <v-list> </v-list>
  </v-container>
</template>

<script>
import EditProgramForm from "../components/EditProgramForm.vue";
import ProgramStudentsTable from "../components/ProgramStudentsTable.vue";
import RankTable from "../components/RankTable.vue";
export default {
  methods: {
    popUpEditSuccess() {
      this.editSuccess = true;
      setTimeout(() => (this.editSuccess = false), 5000);
    },
  },
  components: { ProgramStudentsTable, RankTable, EditProgramForm },
  data() {
    return {
      editSuccess: false,
      rankUpdatedKey: 0,
      programId: this.$route.params.programId,
    };
  },
  computed: {
    program: function () {
      return this.$store.state.programList.find(
        (program) => program.programId == this.programId
      );
    },
  },
};
</script>

<style>
.programHeader {
  padding-bottom: 3%;
}
</style>