<template>
<v-container>
<h1>Students</h1>
  <students-table :students="this.$store.state.studentList"/>
</v-container>
</template>

<script>
import StudentsTable from '../components/StudentsTable.vue'
export default {
  name: "studentsView",
  components: { StudentsTable },

}
</script>

<style>

</style>