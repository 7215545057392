import axios from 'axios';
import store from '../store/index';

const firebaseToken = store.state.token;

const http = axios.create({
    // baseURL: "http://192.168.0.113:8080"
        // baseURL: "http://localhost:8080",
    baseURL: "https://taekwondo-school-check-in.uc.r.appspot.com/"
  });

export default {

    getPhoneNumbers(){
        return http.get('/phone-numbers', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
    
    updatePhoneNumber(editedPhoneNumber){
        return http.put('/phone-numbers', editedPhoneNumber, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },

    deletePhoneNumber(id){
        return http.delete(`/phone-numbers/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    },
    addPhoneNumber(newPhoneNumber){
        return http.post('/phone-numbers', newPhoneNumber, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': firebaseToken
            }
        });
    }
   
}