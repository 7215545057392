<template>
  <v-container>
    <v-alert
      :value="alert"
      border="top"
      type="error"
      transition="scale-transition"
    >
      {{ this.errorMsg }}
    </v-alert>
    <v-alert
      :value="this.editSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Student edited successfully.
    </v-alert>

    <v-row>
      <h1 id="studentName">
        {{ this.student.firstName + " " + this.student.lastName }}
      </h1>
      <v-spacer />
      <v-dialog
        @click:outside="destroyEditStudentForm()"
        v-model="editStudentDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="newEntryBtn"
            @click="editStudentDialog = true"
          >
            Edit Student
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="destroyEditStudentForm()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit Student</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="editStudent()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <edit-student-form
            v-if="showEditStudentForm"
            :student="this.student"
            @studentEditSuccessful="destroyEditStudentFormWithSuccess()"
            ref="editStudentForm"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col>
        <v-lazy
          v-model="isLazyLoader"
          :options="{
            threshold: 0.5,
          }"
          min-height="200"
          transition="fade-transition"
        >
          <v-img
            contain
            aspect-ratio="1"
            max-height="300"
            max-width="200"
            v-bind:src="
              this.student.imageName == ''
                ? 'http://127.0.0.1:8887/img-placeholder.svg'
                : `${this.imageUrl}`
            "
          />
        </v-lazy>
      </v-col>
      <v-col class="studentInfo">
        <h3>Gender: {{ this.student.gender }}</h3>
        <h3>Membership Name: {{ this.membershipName }}</h3>
        <h3>Birth Date: {{ birthDate }}</h3>
        <h3>Belt Size: {{ this.student.beltSize }}</h3>
      </v-col>
      <v-col class="studentInfo">
        <h3>Height: {{ this.student.height }}"</h3>
        <h3>Shirt Size: {{ this.student.shirtSize }}</h3>
        <h3>Shoe Size: {{ this.student.shoeSize }}</h3>
        <h3>Status: {{ this.status }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Enrolled Programs</h2>

        <enrollment-table
          :studentId="this.studentId"
          :membershipId="this.student.membershipId"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import EditStudentForm from "./EditStudentForm.vue";
import firebase from "firebase/compat/app";
import EnrollmentTable from "./EnrollmentTable.vue";

export default {
  components: { EditStudentForm, EnrollmentTable },
  name: "studentData",
  methods: {
    destroyEditStudentForm() {
      this.editStudentDialog = false;
      this.showEditStudentForm = false;
      setTimeout(() => (this.showEditStudentForm = true), 250);
    },
    destroyEditStudentFormWithSuccess() {
      this.editStudentDialog = false;
      this.popUpEditSuccess();
      this.showEditStudentForm = false;
      setTimeout(() => (this.showEditStudentForm = true), 250);
    },
    resetEditStudentForm() {
      this.destroyEditStudentForm();
    },
    editStudent() {
      this.$refs.editStudentForm.updateStudent();
    },
    popUpEditSuccess() {
      this.editSuccess = true;
      setTimeout(() => (this.editSuccess = false), 5000);
    },
    async setImageUrl() {
      if (this.student.imageName != null) {
        try {
          const imageName = this.student.imageName;
          const storageRef = firebase.storage().ref();
          const imageRef = storageRef.child(`${imageName}`);

          this.imageUrl = await imageRef.getDownloadURL();
        } catch (error) {
          this.errorMsg = error.message;
        }
      }
    },
    endDateFormatter(date) {
      let endDateCheck = new Date(date);
      if (endDateCheck.getDate() !== this.dateCheck.getDate()) {
        return moment(date).format(this.dateFormat);
      } else {
        return null;
      }
    },
    checkIsActive() {
      let enrollmentCount = this.enrollments.length;
      let enrollmentCheck = 0;
      this.isActive = true;
      this.enrollments.forEach((enrollment) => {
        if (!enrollment.active) {
          enrollmentCheck++;
        }
        if (enrollmentCount == enrollmentCheck) {
          this.isActive = false;
        }
      });
    },
    getProgramName(programId) {
      let program = this.$store.state.programList.find(
        (programSearch) => programSearch.programId == programId
      );
      return program.name;
    },
    getRankName(rankId) {
      let rank = this.$store.state.rankList.find(
        (rankSearch) => rankSearch.rankId == rankId
      );
      return rank.name;
    },
    determineActive(active) {
      if (active) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
  computed: {
    enrollments: function () {
      return this.$store.state.enrollmentList.filter(
        (enrollment) => enrollment.studentId == this.studentId
      );
    },
    status: function () {
      if (this.isActive) {
        return "Active";
      } else {
        return "Inactive";
      }
    },
    membershipName: function () {
      let idNum = Number(this.student.membershipId);
      let membership = this.$store.state.membershipList.find(
        (membership) => membership.membershipId == idNum
      );
      return membership.membershipName;
    },
  },

  data() {
    return {
      errorMsg: "",
      showEditStudentForm: true,
      dateCheck: new Date("0001-01-01"),
      editStudentDialog: false,
      student: "",
      birthDate: "",
      dateFormat: "MM/DD/YYYY",
      studentId: this.$route.params.id,
      isActive: "",
      imageUrl: "",
      isLazyLoader: false,
      editSuccess: false,
      alert: false,
    };
  },
  created() {
    if (this.student.imageName != "") {
      this.setImageUrl();
    }
    this.student = this.$store.state.studentList.find(
      (student) => student.studentId == this.studentId
    );
    this.birthDate = moment(this.student.birthDate).format(this.dateFormat);
    this.checkIsActive();
  },
  mounted() {
    if (this.student.imageName != "") {
      this.setImageUrl();
    }
  },
};
</script>

<style>
.studentInfo {
  padding-top: 4%;
}
</style>