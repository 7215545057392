<template>
  <!-- change delete button to inactive toggle -->
    <v-data-table 
    :items="programStudents"
    :headers="headers">
        <template 
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ header, value }">
                {{ header.formatter(value) }}
        </template>
        
        <!-- <template v-slot:top>
            <v-toolbar flat>
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on">
                    New Student
                </v-btn>
            </v-toolbar>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="primary" dark @click="viewStudent(item.studentId)">
            <v-icon
                    
                    
                >
                    mdi-eye
                </v-icon>
          </v-btn>
      
    
    </template>
    </v-data-table>
  
</template>

<script>
import moment from "moment";

export default {
    props: {
        programId: [String, Number],
    },
    name: "studentsTable",
  methods: {
     getRankName(studentId){
         let studentIdNumber = Number.parseInt(studentId);
       let studentEnrollment = this.$store.state.enrollmentList.find(enrollment => ((enrollment.studentId == studentIdNumber) && (enrollment.programId == this.programId)));
       let rank = this.$store.state.rankList.find((rank) => studentEnrollment.rankId == rank.rankId);
       return rank.name;
     },
      viewStudent(studentId){
        //think about refactoring to use already received data, reduce calls to db
          let id = Number(studentId);
          this.$router.push({name:'Student View', params: {id}}); 
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
  },
  data: function () {
      return{
        dialog: false,
        enrollmentStudentIds: [],
      dialogDelete: false,
      dateFormat: "MM/DD/YYYY",
      headers: [
         {text: 'Preferred Name', value: 'preferredName', width: "auto"},
         {text: 'Last Name', value: 'lastName', width: "auto"},
        {
          text: "Birth Date",
          value: "birthDate",
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null),
        },
        {text: "Gender",
        value: "gender", width: "5%"},
        {text: "Rank",
        value: "studentId",
        formatter: (x) => (x ? this.getRankName(x) : null)},
        {text: "Height (in)",
        value: "height", width: "5%"},
        {text: "Shirt Size",
        value: "shirtSize", width: "10%"},
        {text: "Belt Size",
        value: "beltSize", width: "5%"},
        {text: "Shoe Size",
        value: "shoeSize", width: "5%"},
        { text: 'Actions', value: 'actions', sortable: false }
      ],
  }
  },
  computed: {
    enrollments: function() {
        return this.$store.state.enrollmentList.filter((enrollment) => enrollment.programId == this.programId);
    },
   
     programStudents: function(){
         return this.$store.state.studentList.filter((student) => this.enrollmentStudentIds.includes(student.studentId));
     }
  },
  created() {
      this.enrollments.forEach((enrollment) => {
          this.enrollmentStudentIds.push(enrollment.studentId);
      });
  },
}
</script>

<style>

</style>