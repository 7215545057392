<template>
  <edit-student-form/>
</template>

<script>
import EditStudentForm from '../components/EditStudentForm.vue'
export default {
  components: { EditStudentForm },

}
</script>

<style>

</style>