<template>
  <v-container>
    <v-alert
      :value="this.addSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Enrollment added successfully.
    </v-alert>
    <v-alert
      :value="this.editSuccess"
      border="top"
      type="success"
      transition="scale-transition"
    >
      Enrollment edited successfully.
    </v-alert>
    <v-data-table
      :items="this.enrollments"
      :headers="this.enrollmentHeaders"
      class="membershipDataTable"
    >
      <template
        v-for="header in enrollmentHeaders.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="primary" 
            dark 
            @click="showEditForm(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          
        <delete-prompt
          :item="item"
          :type="type"
          :typeLower="typeLower"
          v-on:deleteConfirm="deletePhoneNumber(item.enrollmentId)"
        />
      </template>
    </v-data-table>
    <v-row>
      <v-spacer />
      <v-dialog
        @click:outside="destroyAddEnrollmentForm()"
        v-model="addEnrollmentDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            class="newEntryBtn"
            @click="addEnrollmentDialog = true"
          >
            Add Enrollment
          </v-btn>
        </template>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="destroyAddEnrollmentForm()">
              <v-icon> mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Add Enrollment</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="addEnrollment()"> Save </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <add-enrollment-form
            v-if="showAddEnrollmentForm"
            @enrollmentAddSuccessful="resetAddEnrollment"
            :filteredStudentList="[this.$props.studentId]"
            ref="addEnrollmentForm"
            :studentId="this.$props.studentId"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog v-model="editEnrollmentDialog" 
    @click:outside="destroyEditEnrollmentForm()">
          
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Edit Enrollment</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="editEnrollment()">
                  Save
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <edit-enrollment-form 
            :key="destructionVar"
            :enrollment="this.selectedEnrollment"
            ref="editEnrollmentForm"
            @enrollmentEditSuccessful="resetEditEnrollment" />
          </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
import DeletePrompt from "./DeletePrompt.vue";
import enrollmentService from "../services/EnrollmentService";
import EditEnrollmentForm from "./EditEnrollmentForm.vue";
import moment from "moment";
import AddEnrollmentForm from "./AddEnrollmentForm.vue";

export default {
  beforeMount() {
    this.setEnrollments();
  },

  components: { DeletePrompt, EditEnrollmentForm, AddEnrollmentForm },
  props: {
    studentId: [String, Number],
    membershipId: [String, Number],
  },
  methods: {
    showEditForm(item){
      this.destructionVar++;
      this.selectedEnrollment = item;
      this.editEnrollmentDialog = true;
    },
    popUpAddSuccess() {
      this.addSuccess = true;
      setTimeout(() => (this.addSuccess = false), 5000);
    },
    popUpEditSuccess() {
      this.editSuccess = true;
      setTimeout(() => (this.editSuccess = false), 5000);
    },
    resetEditEnrollment() {
      this.destroyEditEnrollmentFormWithSuccess();
      this.setEnrollments();
    },
    resetAddEnrollment() {
      this.destroyAddEnrollmentFormWithSuccess();
      this.setEnrollments();
    },
    editEnrollment() {
      this.$refs.editEnrollmentForm.editEnrollment();
    },
    addEnrollment() {
      this.$refs.addEnrollmentForm.addStudentEnrollment();
    },
    endDateFormatter(date) {
      if (date.toString()  != "0001-01-01") {
        return moment(date).format(this.dateFormat);
      }
    },
    getProgramName(programId) {
      let program = this.$store.state.programList.find(
        (programSearch) => programSearch.programId == programId
      );
      return program.name;
    },
    getRankName(rankId) {
      let rank = this.$store.state.rankList.find(
        (rankSearch) => rankSearch.rankId == rankId
      );
      return rank.name;
    },
    determineActive(active) {
      if (active) {
        return "Yes";
      }
    },
    setEnrollments() {
      this.enrollments = this.$store.state.enrollmentList.filter(
        (enrollment) => enrollment.studentId == this.studentId
      );
    },
    ensureUpdate() {
      this.setPhoneNumbers();
    },
    destroyAddEnrollmentForm() {
      this.addEnrollmentDialog = false;
      this.showAddEnrollmentForm = false;
      setTimeout(() => (this.showAddEnrollmentForm = true), 250);
    },
    destroyAddEnrollmentFormWithSuccess() {
      this.addEnrollmentDialog = false;
      this.popUpAddSuccess();
      this.showAddEnrollmentForm = false;
      setTimeout(() => (this.showAddEnrollmentForm = true), 250);
    },
    destroyEditEnrollmentForm() {
      this.editEnrollmentDialog = false;
      this.showEditEnrollmentForm = false;
      setTimeout(() => (this.showEditEnrollmentForm = true), 250);
    },
    destroyEditEnrollmentFormWithSuccess() {
      this.editEnrollmentDialog = false;
      this.popUpEditSuccess();
      this.showEditEnrollmentForm = false;
      setTimeout(() => (this.showEditEnrollmentForm = true), 250);
    },
    deleteEnrollment(id) {
      const idNum = Number(id);
      enrollmentService
        .deleteEnrollment(idNum)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("DELETE_ENROLLMENT", idNum);
            this.setEnrollments();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error deleting enrollment. ${error.response.status} - ${error.response.statusText}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
        });
    },
  },
  data() {
    return {
      selectedEnrollment: Object,
      destructionVar: 0,
      addSuccess: false,
      editSuccess: false,
      editEnrollmentDialog: false,
      showEditEnrollmentForm: true,
      dateCheck: new Date("0001-01-01"),
      addEnrollmentDialog: false,
      showAddEnrollmentForm: true,
      dateFormat: "MM/DD/YYYY",
      isActive: "",
      imageUrl: "",
      isLazyLoader: false,
      errorMsg: "",
      enrollments: [],
      type: "Enrollment",
      typeLower: "enrollment",
      enrollmentHeaders: [
        {
          text: "Program",
          value: "programId",
          formatter: (x) => (x ? this.getProgramName(x) : null),
        },
        {
          text: "Current Rank",
          value: "rankId",
          formatter: (x) => (x ? this.getRankName(x) : null),
        },
        {
          text: "Start Date",
          value: "startDate",
          formatter: (x) => (x ? moment(x).format(this.dateFormat) : null),
        },
        {
          text: "End Date",
          value: "endDate",
          formatter: (x) => (x ? this.endDateFormatter(x) : ""),
        },
        {
          text: "Active?",
          value: "active",
          formatter: (x) => (x ? this.determineActive(x) : "No"),
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style>
</style>