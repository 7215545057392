<template>
  <v-container>
    <v-alert
      dismissible
      :value="alert"
      border="top"
      type="error"
      transition="scale-transition"
    >
      {{ this.errorMsg }}
    </v-alert>

    <v-form class="dataForm" :disabled="hideSaveStudent" ref="studentForm">
      <v-row>
        <v-col>
          <v-text-field
            v-model="newStudent.firstName"
            label="First Name"
            required
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="newStudent.lastName"
            label="Last Name"
            required
          >
          </v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="newStudent.preferredName"
            label="Preferred Name"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="newStudent.shoeSize"
            label="Shoe Size"
            required
          >
          </v-text-field>
        </v-col>

        <v-col>
          <v-menu
            ref="birthdaySelector"
            v-model="birthdaySelector"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-on:focus="birthdaySelector = true"
                :value="formattedBirthDate"
                label="Birthday"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birthDate"
              @change="setBirthDate"
              @input="birthdaySelector = false"
              :active-picker.sync="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substr(0, 10)
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-text-field
            v-model="newStudent.height"
            label="Height (inches)"
            required
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            :items="beltSizes"
            label="Belt Size"
            v-model="newStudent.beltSize"
          >
          </v-select>
        </v-col>
        <v-col>
          <v-select
            :items="shirtSizes"
            label="Shirt Size"
            v-model="newStudent.shirtSize"
          >
          </v-select>
        </v-col>

        <v-col>
          <v-select
            :items="genderOptions"
            label="Gender"
            v-model="newStudent.gender"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-spacer />
        </v-col>

        <v-col>
          <v-img
            v-on:click="click1"
            contain
            aspect-ratio="1"
            max-height="175"
            v-if="this.tempUrl"
            :src="this.tempUrl"
          />

          <v-img
            v-else
            contain
            max-height="175"
            aspect-ratio="1"
            src="../assets/png/generic-cat2.png"
            class="catImage"
          />
        </v-col>

        <v-col>
          <v-spacer />
        </v-col>
        <input
          type="file"
          ref="input1"
          style="display: none"
          @change="previewImage"
          accept="image/*"
        />
      </v-row>

      <v-row>
        <v-col><v-spacer /></v-col>
        <v-col>
          <div class="text-center">
            <v-btn v-on:click="click1" :disabled="hideSaveStudent"
              >Choose a Photo</v-btn
            >
          </div>
        </v-col>
        <v-col>
          <v-spacer />
        </v-col>
      </v-row>
      <v-row>
        <v-col><v-spacer /></v-col>
        <v-col>
          <v-spacer />
        </v-col>
        <div >
          <v-col>
            <div class="text-end">
              <v-btn @click="addStudent(newStudent)" v-if="!hideSaveStudent">
                Save Student
              </v-btn>
              <v-btn @click="toggleStudentForm()" v-else
                >Add Another Student</v-btn
              >
            </div>
          </v-col>
        </div>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import moment from "moment";
import firebase from "firebase/compat/app";
import studentService from "../services/StudentService";

export default {
  name: "addStudentForm",
  props: {
    membershipId: [String, Number],
  },
  data() {
    return {
      hideSaveStudent: false,
      genderOptions: [
        "Male",
        "Female",
        "Non-Binary",
        "Gender Fluid",
        "Non-Disclosed",
      ],
      alert: false,
      imageData: null,
      img1: null,
      tempUrl: "",
      formattedBirthDate: "",
      errorMsg: "",
      activePicker: null,
      birthdaySelector: false,
      valid: false,
      birthDate: "",
      dateFormat: "MM/DD/YYYY",
      studentId: "",
      beltSizes: [
        "00000",
        "0000",
        "000",
        "00",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
      ],
      shirtSizes: [
        "Child XS",
        "Child S",
        "Child M",
        "Child L",
        "Child XL",
        "Adult XS",
        "Adult S",
        "Adult M",
        "Adult L",
        "Adult XL",
        "Adult XXL",
        "Adult XXXL",
      ],
      newStudent: {
        studentId: "",
        imageName: "",
        firstName: "",
        preferredName: "",
        lastName: "",
        gender: "",
        birthDate: "",
        shoeSize: "",
        beltSize: "",
        height: "",
        shirtSize: "",
      },
      studentClean: {
        studentId: "",
        imageName: "",
        firstName: "",
        preferredName: "",
        lastName: "",
        gender: "",
        birthDate: "",
        shoeSize: "",
        beltSize: "",
        height: "",
        shirtSize: "",
      },
      success: false,
    };
  },
  methods: {
    toggleStudentForm() {
      this.hideSaveStudent = false;
      this.$emit("resetStudentForm");
    },
    addStudent(student) {
      student.membershipId = this.membershipId;
      studentService
        .addStudent(student)
        .then((response) => {
          if (response.status === 200) {
            student.studentId = response.data;
            this.onUpload();
            this.$store.commit("ADD_STUDENT", student);
            this.$emit("studentAddSuccessful", response.data);
            this.resetForm();
            this.hideSaveStudent = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error adding student. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },

    click1() {
      this.$refs.input1.click();
    },
    previewImage(event) {
      this.uploadValue = 0;
      this.img1 = null;
      this.imageData = event.target.files[0];
      const imageName =this.imageData.name;
      const imageNameSlice = imageName.slice(0, imageName.length - 4);
      this.newStudent.imageName = `${imageNameSlice}_200x200.jpg`;
      this.tempUrl = URL.createObjectURL(this.imageData);
    },
    onUpload() {
      if (this.imageData != null) {
        this.img1 = null;
        const storageRef = firebase
          .storage()
          .ref(`${this.imageData.name}`)
          .put(this.imageData);
        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            this.errorMsg = error.message;
          },
          () => {
            this.uploadValue = 100;
          }
        );
      }
    },

    setBirthDate() {
      this.formattedBirthDate = moment(this.birthDate).format(this.dateFormat);
      this.newStudent.birthDate = this.birthDate;
    },
    formatDate(date) {
      return moment(date).format(this.dateFormat);
    },
    getMembershipName(membershipId) {
      let membership = this.$store.state.membershipList.find(
        (membershipSearch) => membershipSearch.membershipId == membershipId
      );
      return `${membership.membershipName}, ${membership.membershipContactName}`;
    },
    resetForm() {
      this.$refs.studentForm.reset();
      this.alert = false;
      this.errorMsg = null;
      setTimeout(
        () => (this.newStudent = Object.assign({}, this.studentClean)),
        250
      );
    },
  },
  computed: {
    
  },
  beforeMount() {
    this.newStudent = Object.assign({}, this.studentClean);
  },
};
</script>

<style>
.dataForm {
  padding: 1.5%;
}

.photoBtn {
  padding-left: 100%;
}
</style>