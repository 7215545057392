<template>
  <v-container>
    <h1>Programs</h1>
    <programs-table />
  </v-container>
</template>

<script>
import ProgramsTable from "../components/ProgramsTable.vue";
export default {
  name: "programs",
  components: { ProgramsTable },
  methods: {},
};
</script>
<style scoped>
</style>
