<template>
      <v-form class="dataForm">
        <v-row>
          <v-col>
            <v-text-field
              label="Phone Number Owner Name"
              v-model="newPhoneNumber.phoneNumberOwnerName"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-combobox
              label="Phone Number Type"
              v-model="newPhoneNumber.phoneNumberType"
              :items="this.phoneNumberTypes"
            ></v-combobox>
          </v-col>

          <v-col>
            <v-text-field
              label="Phone Number"
              v-model="newPhoneNumber.phoneNumber"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

</template>

<script>
import phoneNumberService from "../services/PhoneNumberService"

export default {
  data() {
    return {
      errorMsg: "",
      alert: false,
      phoneNumberTypes: ["Home", "Work", "Cell", "Other"],
      formDialog: false,
      newPhoneNumber: {
        phoneNumberId: "",
        membershipId: this.membershipId,
        phoneNumberOwnerName: "",
        phoneNumberType: "",
        phoneNumber: "",
      },
      cleanPhoneNumber: {
        phoneNumberId: "",
        membershipId: this.membershipId,
        phoneNumberOwnerName: "",
        phoneNumberType: "",
        phoneNumber: "",
      },
    };
  },
  props: {
    membershipId: [String, Number],
  },
  methods: {
    resetAddPhoneNumberForm() {
      this.formDialog = false;
      this.newPhoneNumber = Object.assign({}, this.newPhoneNumberBeforeEdit);
    },
    addPhoneNumber() {
      phoneNumberService
        .addPhoneNumber(this.newPhoneNumber)
        .then((response) => {
          if (response.status === 200) {
            this.newPhoneNumber.phoneNumberId = response.data;
            this.$store.commit("ADD_PHONE_NUMBER", this.newPhoneNumber);
            this.$emit("phoneNumberAddSuccessful");
            this.resetAddPhoneNumberForm();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error adding phone number. ${error.response.status} - ${error.response.statusText}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }
          this.alert = true;
        });
    },
  },
  beforeMount() {
    this.newPhoneNumber = Object.assign({}, this.cleanPhoneNumber);
  },
};
</script>

<style>
.dataForm {
  padding: 1.5%;
}

.newEntryBtn {
  margin: 0.5%;
}
</style>