<template>
  
      <v-form class="dataForm">
        <v-row>
          <v-col>
            <v-text-field
              label="E-Mail Owner Name"
              v-model="emailEdit.emailAddressOwnerName"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-combobox
              label="E-Mail Type"
              v-model="emailEdit.emailAddressType"
              :items="this.emailTypeList"
            ></v-combobox>
          </v-col>

          <v-col>
            <v-text-field
              label="E-Mail"
              v-model="emailEdit.emailAddress"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    
</template>

<script>
import emailAddressService from '../services/EmailAddressService';
export default {
  data() {
    return {
      formDialog: false,
      emailTypeList: ["Personal", "Work", "Other"],
    };
  },
  props: {
    email: Object,
  },
  methods: {
    updateEmail() {
      emailAddressService
        .updateEmail(this.emailEdit)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit("UPDATE_EMAIL", this.emailEdit);
            this.$emit("emailEditSuccessful");
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMsg = `Error updating Membership Type. ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.errorMsg = "Could not connect to server";
          } else {
            this.errorMsg = "Unexpected error";
          }

        });
    
  },
  },
  beforeMount(){
    this.emailEdit = Object.assign({}, this.email);
    this.emailBeforeEdit = Object.assign({}, this.email);
  }
};
</script>

<style>
.dataForm {
  padding: 1.5%;
}

.actionBtn{
  margin: 1.5%;
}
</style>